/* eslint-disable camelcase */
import { isRecord } from './is-record';

export type TierLimitRaw = { storageBytes: number; wcuPerMinute: number, rcuPerMinute: number };

export const isTierLimit = (val: unknown): val is TierLimitRaw => (
  val != null && isRecord(val)
  && val.storageBytes !== undefined
  && val.wcuPerMinute !== undefined
  && val.rcuPerMinute !== undefined
);
