import { ReactElement, useCallback, useContext } from 'react';
import { cx, css } from '@linaria/core';

import { NavMenuButton, NavMenuLink } from './nav.styles';
import { Container } from './container';
import { AppContext } from '../shared/provider';

import { promisify, unsubscribe } from '../lib/polkadot-api/contract-api';

import logo from '../assets/img/logo-light.svg';

const spaceBetween = css`
  justify-content: space-between;
`;

export function Navigation(): ReactElement {
  const { user, updateSubscription } = useContext(AppContext);

  const unsubscribeApi = useCallback(async () => {
    if (user?.address) {
      await promisify(unsubscribe, undefined, user.address);
      await updateSubscription(user.address);
    }
  }, [updateSubscription, user?.address]);

  const renderCommonItems = () => (
    <>
      <li>
        <NavMenuLink exact to="/view">
          Data viewer
        </NavMenuLink>
      </li>
      <li>
        <NavMenuLink exact to="/view-assets">
          Asset viewer
        </NavMenuLink>
      </li>
    </>
  );

  return (
    <Container>
      <nav className="w-full bg-transparent text-white py-16 relative">
        <div id="navbarSupportedContent">
          <div className={cx('flex', spaceBetween)}>
            <div>
              <img src={logo} alt="logo" />
            </div>
            <ul className="flex flex-col md:flex-row justify-start md:justify-end">
              {!user && (
                <>
                  <li>
                    <NavMenuLink exact to="/">
                      Home
                    </NavMenuLink>
                  </li>
                  {renderCommonItems()}
                </>
              )}
              {user && (
                <>
                  <li>
                    <NavMenuLink exact to="/account">
                      Welcome, {user.name}
                    </NavMenuLink>
                  </li>
                  {process.env.NODE_ENV === 'development' && false && (
                    <NavMenuButton onClick={unsubscribeApi} type="button">
                      Unsubscribe
                    </NavMenuButton>
                  )}
                  {renderCommonItems()}
                  <li>
                    <NavMenuLink exact to="/logout">
                      Logout
                    </NavMenuLink>
                  </li>
                </>
              )}
              {!user && (
                <li>
                  <NavMenuLink exact to="/login">
                    Sign Up
                  </NavMenuLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </Container>
  );
}
