/* eslint-disable camelcase */

import { isRecord } from './is-record';

export type SubscriptionDetailsRaw = {
  ok: {
    endDateMs: number;
    subscription: { balance: number; lastUpdateMs: number; startDateMs: number };
  };
};

const isSubscriptionDetails = (val: unknown): val is SubscriptionDetailsRaw => (
  val != null && isRecord(val) && isRecord(val.ok) && isRecord(val.ok.subscription)
  && val.ok.endDateMs !== undefined
  && typeof val.ok.subscription === 'object'
  && typeof val.ok.subscription.balance !== 'undefined'
  && typeof val.ok.subscription.lastUpdateMs !== 'undefined'
  && typeof val.ok.subscription.startDateMs !== 'undefined'
);

export {
  isSubscriptionDetails,
};
