import { importProvider } from '@cere/freeport-sdk';
import { providers } from 'ethers';

import { isRecord, maticNetworkNames, MaticTestnetIdEnum } from '../types';

export const getSigner = async (): Promise<providers.JsonRpcSigner> => {
  // This works only in a browser
  const provider = importProvider();
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();

  try {
    await signer.getAddress();
  } catch (error: unknown) {
    if (isRecord(error) && error.reason === 'unknown account #0') {
      throw new Error('MetaMask is not connected');
    }
  }

  const { chainId } = await signer.provider.getNetwork();

  if (!process.env.REACT_APP_METAMASK_NETWORK_ID) {
    throw new Error('REACT_APP_METAMASK_NETWORK_ID ENV param should be specified!');
  }

  const allowedChainId = process.env.REACT_APP_METAMASK_NETWORK_ID;

  if (allowedChainId !== String(chainId)) {
    const id = Number(allowedChainId) as MaticTestnetIdEnum;

    throw new Error(
      `You should switch your MetaMask client to ${maticNetworkNames[id]}`
    );
  }

  return signer;
};
