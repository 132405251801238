import { css } from '@linaria/core';

export const row = css`
  margin: 0 -15px;
`;

export const col = css`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0 15px;
`;

export const tokenBox = css`
  padding: 2rem 30px;
  border: 2px solid transparent;
  border-radius: 10px;
  background-image: linear-gradient(135deg, #49138C, #341477);
  background-repeat: no-repeat;
  transition: all .5s;
`;

export const tokenBoxHeader = css`
  font-size: 2.4rem;
  left: 3.6rem;
`;

export const tokenPrice = css`
  font-size: 1.6rem;
  line-height: 2rem;
  color: #b492e9;
  font-weight: 400;
  text-align: left;
  margin-bottom: 2rem;
`;

export const tierDescription = css`
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 400;
  background-color: #2b0c65;
  border-radius: 1rem;
  margin-bottom: 2.2rem;
  padding: .5rem 1.5rem 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
`;

export const underline = css`
  text-decoration: underline;
`;

export const logBox = css`
  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
    max-width: 100%;
    word-break: break-all;
  }
`;
