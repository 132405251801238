/* eslint-disable operator-linebreak */
import { isRecord } from './is-record';

export type Token = {
  nftId: string;
  minter: string;
  supply: number;
  quantity: number;
  priceInUsdCents?: number;
  priceInCereUnits?: number;
};

export const defaultToken: Token = {
  nftId: 'nft',
  minter: 'minter',
  supply: 1,
  quantity: 0,
  priceInUsdCents: 0,
  priceInCereUnits: 0,
};

export const isToken = (value: unknown): value is Token =>
  isRecord(value) &&
  typeof value.nftId === 'string' &&
  value.nftId !== '' &&
  typeof value.minter === 'string' &&
  value.minter !== '' &&
  typeof value.supply === 'number' &&
  Number.isInteger(value.supply) &&
  value.supply > 0 &&
  typeof value.quantity === 'number' &&
  Number.isInteger(value.quantity);
