import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';

export async function getAccount(userAddress: string): Promise<InjectedAccountWithMeta> {
  await web3Enable('DDC');
  return web3Accounts().then((accounts) => {
    const userAccount = accounts.find((account) => account.address === userAddress);
    if (!userAccount) {
      throw Error(`No user account matched to address ${userAddress} have found`);
    }
    return userAccount;
  });
}
