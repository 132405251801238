import { ISubmittableResult } from '@polkadot/types/types/extrinsic';
import { web3FromSource } from '@polkadot/extension-dapp';

import { ContractTxResult } from './types';
import { getAccount } from '../tools/get-account';
import { getPolkadotContract } from '../tools/api-tools';
import { User } from '../../../shared/provider';

export async function* refund(user: User): ContractTxResult {
  yield { log: '- Looking for account...' };
  const account = await getAccount(user.address);
  yield { log: '+ Account found' };
  yield { log: '- Connecting to the contract...' };
  const contract = await getPolkadotContract();
  yield { log: '+ Connected' };

  yield { log: '- Executing refund...' };
  const gasLimit = -1;
  const value = 0;
  const { signer } = await web3FromSource(account.meta.source);

  const { gasConsumed } = await contract.query.refund(user.address, { value, gasLimit });

  const txResult: ISubmittableResult = await new Promise((resolve, reject) => {
    contract.tx
      .refund({ value, gasLimit: gasConsumed.toString() })
      .signAndSend(user.address, { signer }, (result) => {
        if (result.isCompleted) {
          resolve((result as unknown) as ISubmittableResult);
        } else if (result.isError) {
          reject(result);
        }
      });
  });

  yield ({ log: '+ Executing refund completed' });

  yield ({ log: '+ Done', result: txResult });
}
