import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { css, cx } from '@linaria/core';

import { Container } from '../../layout/container';
import { btn, btnSmall } from './buttons.styles';
import { useIsAuthenticated } from '../../shared/provider';
import { centerText, header, text } from './home.styles';
import { TiersList } from '../../shared/tiers-list/tiers-list';
import { row } from '../../shared/tiers-list/styles';

const btnBlockTopAndRight = css`
  margin-top: 1rem;
  margin-right: 3rem;
`;

const benefitBox = css`
  padding: 2rem;
  border: 2px solid transparent;
  border-radius: 10px;
  background-image: linear-gradient(135deg, #49138c, #341477);
  background-repeat: no-repeat;
  transition: all 0.5s;
  height: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 1rem;
  align-items: center;
`;

const quickstartStepBox = css`
  padding: 2rem;
  border: 5px;
  border-radius: 15px;
  background-image: linear-gradient(135deg, #49138c, #341477);
  background-repeat: no-repeat;
  transition: all 0.5s;
`;

export const col = css`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0 15px;
  display: table-cell;
`;

export const pressCol = css`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: table-cell;
`;

export const avatarImg = css`
  border-radius: 50%;
`;

export const imgCenter = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const marginTop25 = css`
  margin-top: 25px;
`;

const style = css`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    height: 120px;
  }
`;

export function Home(): ReactElement {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <Redirect to="/account" />;
  }

  return (
    <Container>
      <section>
        <div className="md:pt-24 pb-24 md:flex md:flex-row">
          <div>
            <h1 className={cx(header, 'font-header')}>Decentralized Data Cloud</h1>
            <p className={cx(text, 'text-4xl mb-0 md:mb-6')}>
              Using blockchain identity and data encryption to power CRM data ecosystems and B2C consumer NFT utilities.
            </p>
            <a href="#quickstart" className={cx(btn, btnSmall, btnBlockTopAndRight)}>
              Try now!
            </a>
            <a
              target="_blank"
              title="DDC Wiki"
              rel="noreferrer noopener"
              href="https://docs.cere.network/"
              className={cx(btn, btnSmall, btnBlockTopAndRight)}
            >
              DDC Wiki
            </a>
          </div>
          <div className="mt-4 hidden md:block">
            <img src="/img/main.png" alt="home banner" className="max-w-full h-auto" />
          </div>
        </div>
      </section>
      <section>
        <div className={cx(row, 'flex flex-col md:flex-row')}>
          <div className={col}>
            <div className={cx(benefitBox)}>
              <div className="w-40">
                <img width={50} height={50} src="/img/realtime.png" alt="home banner" className="max-w-full h-auto" />
              </div>
              <div className="ml-4">
                <span className="text-2xl md:text-3xl">
                  Real-time accessibility of decentralized datasets and assets
                </span>
              </div>
            </div>
          </div>
          <div className={col}>
            <div className={cx(benefitBox)}>
              <div>
                <img width={50} height={50} src="/img/blockchain.png" alt="home banner" className="max-w-full h-auto" />
              </div>
              <div className="ml-4">
                <span className="text-2xl md:text-3xl">Interoperable with every major blockchain</span>
              </div>
            </div>
          </div>
          <div className={col}>
            <div className={cx(benefitBox)}>
              <div>
                <img width={50} height={50} src="/img/use-cases.png" alt="use cases" className="max-w-full h-auto" />
              </div>
              <div className="ml-4">
                <span className="text-2xl md:text-3xl">Already in use via Cere Freeport!</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="pt-16 md:pt-32 pb:16 md:pb-32 flex flex-col md:flex-row">
          <div>
            <img
              width={200}
              height={200}
              src="/img/sandeep.jpeg"
              alt="sandeep"
              className={cx(avatarImg, 'w-40 md:w-24 md:w-auto max-w-full h-auto')}
            />
          </div>
          <div className="mt-8 md:ml-8">
            <p className="text-3xl md:text-4xl">
              “Cere’s Decentralized Data Cloud takes models like Snowflake’s and makes it much easier for dApps to
              integrate with decentralized datasets. We have been working closely with the Cere team, and we are excited
              that developers, companies, and individuals in our ecosystem can leverage smart contracts deployed on
              Polygon to include trustless data transfers on top of value transfers via Cere DDC. ”
            </p>
            <p className="text-3xl md:text-4xl">Sandeep Nailwal, Co-founder and COO of Polygon.</p>
          </div>
        </div>
      </section>
      <hr className="solid" />
      <section id="quickstart">
        <div className="pt-16 md:pt-32">
          <div className={centerText}>
            <h3>The Cere DDC is available for use now!</h3>
            <p className="italic md:text-3xl">
              You can use your Polkadot.js extension to connect to the Cere testnet where the Cere DDC smart contract
              runs on.
            </p>
          </div>
          <div className="grid grid-rows-5 gap-y-4 md:gap-x-8 md:grid-cols-5 md:grid-rows-1">
            <div className={cx(quickstartStepBox, 'p-4')}>
              <span className={cx('text-3xl', text)}>1. Install & setup polkadot.js extension</span>
            </div>
            <div className={cx(quickstartStepBox)}>
              <span className={cx('text-3xl', text)}>
                {process.env.ENV !== 'mainnet' ? (
                  <p>
                    2. Request Cere tokens from&nbsp;
                    <a href="https://laboratory.cere.network/#/friend-bot" target="_blank" rel="noreferrer">
                      Faucet
                    </a>
                  </p>
                ) : (
                  '2. Deposit CERE tokens in your polkadot.js wallet'
                )}
              </span>
            </div>
            <div className={cx(quickstartStepBox)}>
              <span className={cx('text-3xl', text)}>3. Sign up / select tier for DDC</span>
            </div>
            <div className={cx(quickstartStepBox)}>
              <span className={cx('text-3xl', text)}>4. Send data to DDC</span>
            </div>
            <div className={cx(quickstartStepBox)}>
              <span className={cx('text-3xl', text)}>5. Check data on decentralized data viewer</span>
            </div>
          </div>
        </div>
      </section>
      <TiersList />
      <hr className="solid" />
      <section>
        <div className="pt-16 pb-16 md:pt-32 md:pb-32">
          <div className={centerText}>
            <h3>DDC in the press</h3>
          </div>
          <div className="grid gap-y-4 md:grid-cols-3 md:gap-x-8">
            <div>
              <div className={style}>
                <img width={70} height={70} src="/img/decrypt.png" alt="home banner" className="mx-auto block" />
              </div>
              <div className="text-center text-3xl">
                <a
                  className={text}
                  href="https://decrypt.co/52413/cere-network-polkadots-first-data-cloud-platform-raises-funds-for-launch"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>Cere Network: Polkadot&#39;s First Data Cloud Platform Raises Funds for Launch</i>
                </a>
              </div>
            </div>
            <div>
              <div className={style}>
                <img width={200} height={100} src="/img/venture-beat.png" alt="home banner" className="mx-auto block" />
              </div>
              <div className="text-center text-3xl">
                <a
                  className={text}
                  href="https://venturebeat.com/2021/03/29/cere-network-raises-5-million-to-create-decentralized-data-cloud-platform/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>Cere Network raises $5 million to create decentralized data cloud platform</i>
                </a>
              </div>
            </div>
            <div className="mt-8 md:mt-0">
              <div className={style}>
                <img width={200} height={120} src="/img/coindesk.png" alt="use cases" className="mx-auto block" />
              </div>
              <div className="text-center text-3xl italic">
                <a
                  className={text}
                  href="https://www.coindesk.com/business/2021/09/09/cere-network-raises-31m-in-funding-round-led-by-republic-polygon/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    Cere Network, the developer of a blockchain-based customer relations management system, raised $31
                    million in funding for its DDC
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}
