/* eslint-disable no-console */
import { ReactElement, useCallback, useContext, useEffect } from 'react';
import { v4 } from 'uuid';
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';

import { cx } from '@linaria/core';
import { AccountField } from './account-field';
import { btn, btnSmall } from '../home/buttons.styles';
import { OperationProgress } from '../../shared/operation-progress/operation-progress';
import { AppContext, User } from '../../shared/provider';
import { getUserPubKey } from './user-pub-keys';
import { Subscriber } from '../../lib/polkadot-api/contract-api/types';
import { getCompletedStepLog, getCurrentStepLog } from '../../lib/polkadot-api';
import { sendData } from '../../lib/polkadot-api/send-data';
import { useSafeState } from '../../lib/hooks';
import { ALL_PATHS } from '../../lib/crypto-v1';
import { logBox, tokenBox } from '../../shared/tiers-list/styles';

export function AccountSendForm(): ReactElement {
  const [id, setId] = useSafeState(v4());
  const [data, setData] = useSafeState('');
  const [paths, setPaths] = useSafeState(ALL_PATHS);
  const [time, setTime] = useSafeState(new Date().toISOString());
  const [userPub, setUserPub] = useSafeState(getUserPubKey());
  const [isEncrypted, setIsEncrypted] = useSafeState(true);
  const [submitting, setSubmitting] = useSafeState(false);
  const [errors, setErrors] = useSafeState<string[]>([]);
  const [current, setCurrent] = useSafeState<string[]>([]);
  const [completed, setCompleted] = useSafeState<string[]>([]);
  const [account, setAccount] = useSafeState<InjectedAccountWithMeta | null>(null);
  const { user, ddcClient } = useContext(AppContext);

  const subscriber: Subscriber = useCallback(
    (log: string | null, err) => {
      if (err) {
        setErrors((errs) => errs.concat(err.message));
      } else {
        const currentLog = getCurrentStepLog(log);
        const completedLog = getCompletedStepLog(log);
        if (completedLog) {
          setCompleted((logs) => logs.concat(completedLog));
        } else if (currentLog) {
          setCurrent((logs) => logs.concat(currentLog));
        }
      }
    },
    [setCompleted, setCurrent, setErrors],
  );

  const generateId = useCallback(() => setId(v4()), [setId]);
  const generateTime = useCallback(() => setTime(new Date().toISOString()), [setTime]);
  const generateUserPub = useCallback(() => setUserPub(getUserPubKey()), [setUserPub]);

  const resetForm = useCallback(() => {
    setData('');
    generateId();
    generateTime();
  }, [generateId, generateTime, setData]);

  const resetLog = useCallback(() => {
    setCurrent([]);
    setCompleted([]);
    setErrors([]);
  }, [setCompleted, setCurrent, setErrors]);

  const init = useCallback(async () => {
    await web3Enable('DDC');
    const accounts = await web3Accounts();
    setAccount(accounts.find((extAccount) => extAccount.address === user?.address) || null);
  }, [setAccount, user]);

  const submit = useCallback(
    async (e) => {
      const formData = new FormData(e.currentTarget);
      resetLog();
      setSubmitting(true);
      e.preventDefault();
      const request = {
        id: formData.get('id') as string,
        userPubKey: formData.get('userPubKey') as string,
        timestamp: formData.get('timestamp') as string,
        data: `${formData.get('data') as string}`,
        encryptionKey: formData.get('encKey') as string,
        isEncrypted: Boolean(formData.get('isEncrypted')),
        paths: (formData.get('paths') as string).split(',').map((part) => part.trim()),
      };

      try {
        await sendData({ user: user as User, request, ddcClient, subscriber });
        setSubmitting(false);
        resetForm();
      } catch (err) {
        console.log({err});
        setSubmitting(false);
      }
    },
    [ddcClient, resetForm, resetLog, setSubmitting, subscriber, user],
  );

  useEffect(() => {
    init().then(() => null);
  }, [init]);

  return (
    <div className="items-baseline grid md:grid-cols-[6fr,4fr] gap-x-0 gap-y-4 md:gap-x-10 md:gap-y-0 pb-20">
      <div>
        <h3>Request builder</h3>
        <form onSubmit={submit} className="pb-8">
          <AccountField readonly name="id" label="Unique ID" value={id}>
            <button type="button" onClick={generateId} className={cx(btn, btnSmall)}>
              Generate
            </button>
          </AccountField>
          <AccountField readonly name="userPubKey" label="User public key" value={userPub}>
            <button type="button" onClick={generateUserPub} className={cx(btn, btnSmall)}>
              Generate
            </button>
          </AccountField>
          <AccountField readonly name="timestamp" label="Timestamp in ISO 8601 format" value={time}>
            <button type="button" onClick={generateTime} className={cx(btn, btnSmall)}>
              Generate
            </button>
          </AccountField>
          <AccountField
            required
            monospaced
            name="paths"
            label="JSON paths to encrypt"
            onChange={setPaths}
            value={paths}
          />
          <AccountField
            monospaced
            required
            height={250}
            name="data"
            label="Data"
            fieldType="textarea"
            onChange={setData}
            value={data}
          />
          <AccountField
            disabled={!isEncrypted}
            required={isEncrypted}
            name="encKey"
            label="Master encryption key"
            value="secret"
          >
            <label className="flex text-2xl items-center">
              <input
                className="mr-2"
                type="checkbox"
                checked={isEncrypted}
                name="isEncrypted"
                onChange={(e) => setIsEncrypted(e?.target?.checked ?? true)}
              />
              Encrypt data
            </label>
          </AccountField>
          <button className={cx(btn, 'relative -left-2 mt-4')}  data-disabled={submitting || !account} type="submit">
            Sign & Submit
          </button>
        </form>
      </div>
      <div className={cx(tokenBox, logBox)}>
        <h3 className="text-[2.4rem] leading-[3.6rem]">Log</h3>
        <OperationProgress justify="left" errors={errors} current={current} completed={completed} />
      </div>
    </div>
  );
}
