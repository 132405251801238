import { getPolkadotContract } from '../tools/api-tools';
import { ContractQueryResult } from './types';

export async function* getSubscriptionDetails(userAddress: string): ContractQueryResult {
  yield { log: '- Connecting to the contract...' };
  const contract = await getPolkadotContract();
  yield { log: '+ Connected' };
  yield { log: '- Executing get_subscription_details_of...' };

  const response = await contract.query.getSubscriptionDetailsOf(
    userAddress,
    {
      storageDepositLimit: null,
      gasLimit: -1,
    },
    userAddress,
  );

  yield {
    result: response,
    log: '+ Executing get_subscription_details_of completed',
  };
}
