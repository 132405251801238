export const mockData = [
  {
    'timestamp': '2024-09-15T09:32:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400140,
      'event_type': 'TRIP_START',
      'id': '920cbd6e-3ac6-45fc-8b74-05adc5f6387f',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.1087645,
      'longitude': 19.3354947,
      'description': 'Trip started',
    })
  },
  {
    'timestamp': '2024-09-15T09:36:53Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400140,
      'event_type': 'TRIP_DATA',
      'id': 'bb072872-eceb-475c-b8e2-610091bf5cd5',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'start_time': '2024-09-15T09:32:00Z',
      'end_time': '2024-09-15T09:36:53Z',
      'geolocation_data': [
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:32:10Z',
          'latitude': 50.10984535,
          'longitude': 19.337305070000003,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:32:20Z',
          'latitude': 50.1109262,
          'longitude': 19.33911544,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:32:30Z',
          'latitude': 50.11200705,
          'longitude': 19.34092581,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:32:40Z',
          'latitude': 50.1130879,
          'longitude': 19.34273618,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:32:50Z',
          'latitude': 50.11416875,
          'longitude': 19.34454655,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:33:00Z',
          'latitude': 50.1152496,
          'longitude': 19.34635692,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:33:10Z',
          'latitude': 50.11633045,
          'longitude': 19.34816729,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:33:20Z',
          'latitude': 50.1174113,
          'longitude': 19.34997766,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:33:30Z',
          'latitude': 50.11849215,
          'longitude': 19.35178803,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:33:40Z',
          'latitude': 50.119573,
          'longitude': 19.3535984,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:33:50Z',
          'latitude': 50.12065385,
          'longitude': 19.35540877,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:34:00Z',
          'latitude': 50.1217347,
          'longitude': 19.35721914,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:34:10Z',
          'latitude': 50.12281555,
          'longitude': 19.35902951,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:34:20Z',
          'latitude': 50.1238964,
          'longitude': 19.36083988,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:34:30Z',
          'latitude': 50.12497725,
          'longitude': 19.36265025,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:34:40Z',
          'latitude': 50.1260581,
          'longitude': 19.36446062,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:34:50Z',
          'latitude': 50.12713895,
          'longitude': 19.36627099,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:35:00Z',
          'latitude': 50.1282198,
          'longitude': 19.36808136,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:35:10Z',
          'latitude': 50.12930065,
          'longitude': 19.36989173,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:35:20Z',
          'latitude': 50.1303815,
          'longitude': 19.3717021,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:35:30Z',
          'latitude': 50.13146235,
          'longitude': 19.37351247,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:35:40Z',
          'latitude': 50.1325432,
          'longitude': 19.37532284,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:35:50Z',
          'latitude': 50.13362405,
          'longitude': 19.37713321,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:36:00Z',
          'latitude': 50.1347049,
          'longitude': 19.37894358,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:36:10Z',
          'latitude': 50.13578575,
          'longitude': 19.38075395,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:36:20Z',
          'latitude': 50.1368666,
          'longitude': 19.38256432,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:36:30Z',
          'latitude': 50.13794745,
          'longitude': 19.38437469,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:36:40Z',
          'latitude': 50.1380283,
          'longitude': 19.38618506,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-15T09:36:50Z',
          'latitude': 50.13910915,
          'longitude': 19.38799543,
        },
        {
          'event_type': 'TRIP_END',
          'timestamp': '2024-09-15T09:36:53Z',
          'latitude': 50.13265870000001,
          'longitude': 19.3848108,
        }
      ],
    })
  },
  {
    'timestamp': '2024-09-15T09:36:53Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400140,
      'event_type': 'TRIP_END',
      'id': '1a2b71da-c04b-4747-8c28-b5bff581b44c',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.13265870000001,
      'longitude': 19.3848108,
      'description': 'Trip ended',
    })
  },
  {
    'timestamp': '2024-09-18T08:15:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400141,
      'event_type': 'TRIP_START',
      'id': 'b2c3d4e5-f6a7-8901-bcde-f1234567890a',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.142000,
      'longitude': 19.402000,
      'description': 'No LimeBike found in vicinity',
    })
  },
  {
    'timestamp': '2024-09-18T08:15:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400141,
      'event_type': 'TRIP_END',
      'id': 'b2c3d4e5-f6a7-8901-bcde-f1234567890a',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.142000,
      'longitude': 19.402000,
      'description': 'No LimeBike found in vicinity',
    })
  },
  {
    'timestamp': '2024-09-19T16:10:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 618037,
      'event_type': 'TRIP_START',
      'id': 'c8266482-be95-4309-9416-e71464f9636e',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.1041143,
      'longitude': 19.3219251,
      'description': 'Trip started'
    })
  },
  {
    'timestamp': '2024-09-19T16:11:57Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 618037,
      'event_type': 'TRIP_DATA',
      'id': 'c895b09e-69e2-4838-b0bc-7d6a41c14a4b',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'start_time': '2024-09-19T16:10:00Z',
      'end_time': '2024-09-19T16:11:57Z',
      'geolocation_data': [
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:10:00Z',
          'latitude': 50.1041143,
          'longitude': 19.3219251
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:10:10Z',
          'latitude': 50.1043439,
          'longitude': 19.32348915
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:10:28Z',
          'latitude': 50.1045735,
          'longitude': 19.3250532
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:10:38Z',
          'latitude': 50.10520475,
          'longitude': 19.3249058
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:10:48Z',
          'latitude': 50.105836,
          'longitude': 19.3247584
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:10:58Z',
          'latitude': 50.10608253333333,
          'longitude': 19.3263713
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:11:08Z',
          'latitude': 50.10632906666667,
          'longitude': 19.3279842
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:11:27Z',
          'latitude': 50.1065756,
          'longitude': 19.3295971
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:11:37Z',
          'latitude': 50.107122825,
          'longitude': 19.3310715
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:11:47Z',
          'latitude': 50.107670049999996,
          'longitude': 19.3325459
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-19T16:11:57Z',
          'latitude': 50.108217275,
          'longitude': 19.334020300000002
        }
      ]
    })
  },
  {
    'timestamp': '2024-09-19T16:11:57Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 618037,
      'event_type': 'TRIP_END',
      'id': '98cceabb-dfcf-44f1-ac1e-f5a202ee1b7e',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.108217275,
      'longitude': 19.334020300000002,
      'description': 'Trip ended'
    })
  },
  {
    'timestamp': '2024-09-22T11:20:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 171601,
      'event_type': 'TRIP_START',
      'id': 'c3d4e5f6-a7b8-9012-cdef-1234567890ab',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.143000,
      'longitude': 19.403000,
      'description': 'No LimeBike found in vicinity',
    })
  },
  {
    'timestamp': '2024-09-22T11:20:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 171601,
      'event_type': 'TRIP_END',
      'id': 'c3d4e5f6-a7b8-9012-cdef-1234567890ab',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.143000,
      'longitude': 19.403000,
      'description': 'No LimeBike found in vicinity',
    })
  },
  {
    'timestamp': '2024-09-27T05:30:09Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 573818,
      'event_type': 'TRIP_START',
      'id': '23aa2c88-ec76-4b09-9e55-2a6c61c5839c',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.13265870000001,
      'longitude': 19.3848108,
      'description': 'Trip started',
    })
  },
  {
    'timestamp': '2024-09-27T05:32:56Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 573818,
      'event_type': 'TRIP_DATA',
      'id': '0de2dc00-9e0b-458b-9cbf-dbb9de66d4e9',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'start_time': '2024-09-27T07:30:09Z',
      'end_time': '2024-09-27T07:32:56Z',
      'geolocation_data': [
        {
          'event_type': 'TRIP_START',
          'timestamp': '2024-09-27T07:30:09Z',
          'latitude': 50.13265870000001,
          'longitude': 19.3848108
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:30:09Z',
          'latitude': 50.13265870000001,
          'longitude': 19.3848108
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:30:19Z',
          'latitude': 50.13286646666668,
          'longitude': 19.38632611111111
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:30:29Z',
          'latitude': 50.13307423333334,
          'longitude': 19.387841422222223
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:30:39Z',
          'latitude': 50.13328200000001,
          'longitude': 19.389356733333333
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:30:49Z',
          'latitude': 50.13348976666667,
          'longitude': 19.390872044444443
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:30:59Z',
          'latitude': 50.13369753333334,
          'longitude': 19.392387355555556
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:31:09Z',
          'latitude': 50.1339053,
          'longitude': 19.393902666666666
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:31:19Z',
          'latitude': 50.13411306666667,
          'longitude': 19.395417977777775
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:31:29Z',
          'latitude': 50.134320833333334,
          'longitude': 19.39693328888889
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:31:46Z',
          'latitude': 50.1345286,
          'longitude': 19.3984486
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:31:56Z',
          'latitude': 50.135115812500004,
          'longitude': 19.398755312499997
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:32:06Z',
          'latitude': 50.135703025,
          'longitude': 19.399062025
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:32:16Z',
          'latitude': 50.1362902375,
          'longitude': 19.399368737499998
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:32:26Z',
          'latitude': 50.13687745,
          'longitude': 19.399675449999997
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:32:36Z',
          'latitude': 50.1374646625,
          'longitude': 19.3999821625
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:32:46Z',
          'latitude': 50.138051875,
          'longitude': 19.400288874999998
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:32:56Z',
          'latitude': 50.138639087499996,
          'longitude': 19.4005955875
        },
        {
          'event_type': 'TRIP_END',
          'timestamp': '2024-09-27T07:32:56Z',
          'latitude': 50.138639087499996,
          'longitude': 19.4005955875
        }
      ]
    })
  },
  {
    'timestamp': '2024-09-27T05:32:56Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 573818,
      'event_type': 'TRIP_END',
      'id': 'efa7a6a7-2cf2-471f-93c6-3454e13b785b',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.138639087499996,
      'longitude': 19.4005955875,
      'description': 'Trip ended',
    })
  },
  {
    'timestamp': '2024-09-27T05:35:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 494344,
      'event_type': 'TRIP_START',
      'id': '9746b4e5-dff5-43f3-92ab-1690c3b21e3a',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.1392263,
      'longitude': 19.4009023,
      'description': 'Trip started',
    })
  },
  {
    'timestamp': '2024-09-27T05:39:12Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 494344,
      'id': '0d1d12b4-b671-442d-a26a-0b812d9d069f',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'event_type': 'TRIP_DATA',
      'start_time': '2024-09-27T07:35:00Z',
      'end_time': '2024-09-27T07:39:12Z',
      'geolocation_data': [
        {
          'event_type': 'TRIP_START',
          'timestamp': '2024-09-27T07:35:00Z',
          'latitude': 50.1392263,
          'longitude': 19.4009023
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:35:00Z',
          'latitude': 50.1392263,
          'longitude': 19.4009023
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:35:10Z',
          'latitude': 50.1396462,
          'longitude': 19.40111015
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:35:23Z',
          'latitude': 50.14006610000001,
          'longitude': 19.401318
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:35:33Z',
          'latitude': 50.140233033333345,
          'longitude': 19.400593583333333
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:35:43Z',
          'latitude': 50.14039996666668,
          'longitude': 19.399869166666665
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:35:53Z',
          'latitude': 50.14056690000001,
          'longitude': 19.399144749999998
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:36:03Z',
          'latitude': 50.140733833333336,
          'longitude': 19.398420333333334
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:36:13Z',
          'latitude': 50.14090076666667,
          'longitude': 19.397695916666667
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:36:31Z',
          'latitude': 50.1410677,
          'longitude': 19.3969715
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:36:41Z',
          'latitude': 50.14041996666667,
          'longitude': 19.39564645833333
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:36:51Z',
          'latitude': 50.13977223333333,
          'longitude': 19.394321416666667
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:37:01Z',
          'latitude': 50.1391245,
          'longitude': 19.392996375
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:37:11Z',
          'latitude': 50.13847676666667,
          'longitude': 19.39167133333333
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:37:21Z',
          'latitude': 50.13782903333333,
          'longitude': 19.390346291666667
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:37:31Z',
          'latitude': 50.1371813,
          'longitude': 19.38902125
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:37:41Z',
          'latitude': 50.13653356666667,
          'longitude': 19.38769620833333
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:37:51Z',
          'latitude': 50.13588583333333,
          'longitude': 19.386371166666667
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:38:01Z',
          'latitude': 50.1352381,
          'longitude': 19.385046125
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:38:11Z',
          'latitude': 50.13459036666667,
          'longitude': 19.38372108333333
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:38:21Z',
          'latitude': 50.133942633333334,
          'longitude': 19.382396041666667
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:38:35Z',
          'latitude': 50.1332949,
          'longitude': 19.381071
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:38:49Z',
          'latitude': 50.1322951,
          'longitude': 19.3811542
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:38:59Z',
          'latitude': 50.1324769,
          'longitude': 19.3829825
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T07:39:12Z',
          'latitude': 50.13265870000001,
          'longitude': 19.3848108
        },
        {
          'event_type': 'TRIP_END',
          'timestamp': '2024-09-27T07:39:12Z',
          'latitude': 50.13265870000001,
          'longitude': 19.3848108
        }
      ]
    })
  },
  {
    'timestamp': '2024-09-27T05:39:12Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 494344,
      'event_type': 'TRIP_END',
      'id': 'b141a401-b976-4d7d-9b57-c88bc8d6d419',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.13265870000001,
      'longitude': 19.3848108,
      'description': 'Trip ended'
    })
  },
  {
    'timestamp': '2024-09-27T14:30:09Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 171600,
      'event_type': 'TRIP_START',
      'id': 'c682a888-fa8a-4109-80fe-d13645ae0d33',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.13265870000001,
      'longitude': 19.3848108,
      'description': 'Trip started',
    })
  },
  {
    'timestamp': '2024-09-01T14:37:25Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      "trip_id": 171600,
      "id": "d6006ee5-f871-4af7-89e9-7fa3ea9c1c27",
      "app_id": 2101,
      "account_id": "2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78",
      "event_type": "TRIP_DATA",
      "start_time": "2024-09-27T16:30:09Z",
      "end_time": "2024-09-27T16:37:25Z",
      "geolocation_data": [
          {
              "event_type": "TRIP_START",
              "timestamp": "2024-09-27T16:30:09Z",
              "latitude": 50.13265870000001,
              "longitude": 19.3848108
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:30:09Z",
              "latitude": 50.13265870000001,
              "longitude": 19.3848108
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:30:19Z",
              "latitude": 50.132537500000005,
              "longitude": 19.383591933333335
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:30:29Z",
              "latitude": 50.1324163,
              "longitude": 19.382373066666666
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:30:45Z",
              "latitude": 50.1322951,
              "longitude": 19.3811542
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:31:02Z",
              "latitude": 50.1332949,
              "longitude": 19.381071
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:31:12Z",
              "latitude": 50.13282853333334,
              "longitude": 19.37957835
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:31:22Z",
              "latitude": 50.13236216666667,
              "longitude": 19.3780857
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:31:32Z",
              "latitude": 50.1318958,
              "longitude": 19.376593049999997
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:31:42Z",
              "latitude": 50.13142943333334,
              "longitude": 19.375100399999997
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:31:52Z",
              "latitude": 50.130963066666666,
              "longitude": 19.373607749999998
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:32:03Z",
              "latitude": 50.1304967,
              "longitude": 19.3721151
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:32:13Z",
              "latitude": 50.129546168000005,
              "longitude": 19.370425368
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:32:23Z",
              "latitude": 50.128595636,
              "longitude": 19.368735636
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:32:33Z",
              "latitude": 50.127645104,
              "longitude": 19.367045903999998
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:32:43Z",
              "latitude": 50.126694572000005,
              "longitude": 19.365356172
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:32:53Z",
              "latitude": 50.12574404,
              "longitude": 19.36366644
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:33:03Z",
              "latitude": 50.124793508,
              "longitude": 19.361976708
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:33:13Z",
              "latitude": 50.123842976,
              "longitude": 19.360286975999998
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:33:23Z",
              "latitude": 50.122892444,
              "longitude": 19.358597244
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:33:33Z",
              "latitude": 50.121941912000004,
              "longitude": 19.356907512
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:33:43Z",
              "latitude": 50.12099138,
              "longitude": 19.35521778
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:33:53Z",
              "latitude": 50.120040848,
              "longitude": 19.353528047999998
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:34:03Z",
              "latitude": 50.119090316000005,
              "longitude": 19.351838316
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:34:13Z",
              "latitude": 50.118139784,
              "longitude": 19.350148584
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:34:23Z",
              "latitude": 50.117189252,
              "longitude": 19.348458852
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:34:33Z",
              "latitude": 50.116238720000005,
              "longitude": 19.346769119999998
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:34:43Z",
              "latitude": 50.115288188,
              "longitude": 19.345079388
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:34:53Z",
              "latitude": 50.114337656000004,
              "longitude": 19.343389656
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:35:03Z",
              "latitude": 50.113387124000006,
              "longitude": 19.341699924
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:35:13Z",
              "latitude": 50.112436592,
              "longitude": 19.340010191999998
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:35:23Z",
              "latitude": 50.111486060000004,
              "longitude": 19.33832046
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:35:33Z",
              "latitude": 50.110535528,
              "longitude": 19.336630728
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:35:43Z",
              "latitude": 50.109584996,
              "longitude": 19.334940996
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:35:53Z",
              "latitude": 50.108634464000005,
              "longitude": 19.333251263999998
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:36:03Z",
              "latitude": 50.107683932,
              "longitude": 19.331561532
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:36:16Z",
              "latitude": 50.1067334,
              "longitude": 19.3298718
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:36:26Z",
              "latitude": 50.106481,
              "longitude": 19.3281473
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:36:36Z",
              "latitude": 50.1062286,
              "longitude": 19.3264228
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:36:53Z",
              "latitude": 50.1059762,
              "longitude": 19.3246983
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:37:03Z",
              "latitude": 50.10527485,
              "longitude": 19.32487575
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:37:15Z",
              "latitude": 50.1045735,
              "longitude": 19.3250532
          },
          {
              "event_type": "TRIP_GEO_DATA",
              "timestamp": "2024-09-27T16:37:25Z",
              "latitude": 50.1043439,
              "longitude": 19.32348915
          },
          {
              "event_type": "TRIP_END",
              "timestamp": "2024-09-27T16:37:25Z",
              "latitude": 50.1043439,
              "longitude": 19.32348915
          }
      ]
  })
  },
  {
    'timestamp': '2024-09-01T14:37:25Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 171600,
      'event_type': 'TRIP_END',
      'id': '378099b3-b65b-406b-84d8-c629791f44cc',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.1043439,
      'longitude': 19.32348915,
      'description': 'Trip ended',
    })
  },
  {
    'timestamp': '2024-09-27T16:10:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 618037,
      'event_type': 'TRIP_START',
      'id': 'c8266482-be95-4309-9416-e71464f9636e',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.1041143,
      'longitude': 19.3219251,
      'description': 'Trip started',
    })
  },
  {
    'timestamp': '2024-09-27T16:11:57Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 618037,
      'event_type': 'TRIP_DATA',
      'id': 'c895b09e-69e2-4838-b0bc-7d6a41c14a4b',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'start_time': '2024-09-27T18:10:00Z',
      'end_time': '2024-09-27T18:11:57Z',
      'geolocation_data': [
        {
          'event_type': 'TRIP_START',
          'timestamp': '2024-09-27T18:10:00Z',
          'latitude': 50.1041143,
          'longitude': 19.3219251
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:10:00Z',
          'latitude': 50.1041143,
          'longitude': 19.3219251
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:10:10Z',
          'latitude': 50.1043439,
          'longitude': 19.32348915
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:10:28Z',
          'latitude': 50.1045735,
          'longitude': 19.3250532
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:10:38Z',
          'latitude': 50.10520475,
          'longitude': 19.3249058
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:10:48Z',
          'latitude': 50.105836,
          'longitude': 19.3247584
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:10:58Z',
          'latitude': 50.10608253333333,
          'longitude': 19.3263713
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:11:08Z',
          'latitude': 50.10632906666667,
          'longitude': 19.3279842
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:11:27Z',
          'latitude': 50.1065756,
          'longitude': 19.3295971
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:11:37Z',
          'latitude': 50.107122825,
          'longitude': 19.3310715
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:11:47Z',
          'latitude': 50.107670049999996,
          'longitude': 19.3325459
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:11:57Z',
          'latitude': 50.108217275,
          'longitude': 19.334020300000002
        },
        {
          'event_type': 'TRIP_END',
          'timestamp': '2024-09-27T18:11:57Z',
          'latitude': 50.108217275,
          'longitude': 19.334020300000002
        }
      ]
    })
  },
  {
    'timestamp': '2024-09-27T16:11:57Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 618037,
      'event_type': 'TRIP_END',
      'id': '98cceabb-dfcf-44f1-ac1e-f5a202ee1b7e',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.108217275,
      'longitude': 19.334020300000002,
      'description': 'Trip ended',
    })
  },
  {
    'timestamp': '2024-09-27T16:20:00Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400140,
      'event_type': 'TRIP_START',
      'id': '920cbd6e-3ac6-45fc-8b74-05adc5f6387f',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.1087645,
      'longitude': 19.3354947,
      'description': 'Trip started',
    })
  },
  {
    'timestamp': '2024-09-27T16:24:53Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400140,
      'event_type': 'TRIP_DATA',
      'id': 'bb072872-eceb-475c-b8e2-610091bf5cd5',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'start_time': '2024-09-27T18:20:00Z',
      'end_time': '2024-09-27T18:24:53Z',
      'geolocation_data': [
        {
          'event_type': 'TRIP_START',
          'timestamp': '2024-09-27T18:20:00Z',
          'latitude': 50.1087645,
          'longitude': 19.3354947,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:20:00Z',
          'latitude': 50.1087645,
          'longitude': 19.3354947,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:20:10Z',
          'latitude': 50.10984535,
          'longitude': 19.337305070000003,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:20:20Z',
          'latitude': 50.1109262,
          'longitude': 19.33911544,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:20:30Z',
          'latitude': 50.11200705,
          'longitude': 19.34092581,
        },
        {
          'event_type': 'TRIP_GEO_DATA',
          'timestamp': '2024-09-27T18:24:53Z',
          'latitude': 50.13265870000001,
          'longitude': 19.3848108,
        },
        {
          'event_type': 'TRIP_END',
          'timestamp': '2024-09-27T18:24:53Z',
          'latitude': 50.13265870000001,
          'longitude': 19.3848108,
        }
      ],
    })
  },
  {
    'timestamp': '2024-09-27T16:24:53Z',
    'userPubKey': 'key',
    'appPubKey': 'key',
    'data': JSON.stringify({
      'trip_id': 400140,
      'event_type': 'TRIP_END',
      'id': '1a2b71da-c04b-4747-8c28-b5bff581b44c',
      'app_id': 2101,
      'account_id': '2ce686f936c69f91d91c30b4f4c6dc54d20dc13e50cdfba0b98f63dc57f27b78',
      'latitude': 50.13265870000001,
      'longitude': 19.3848108,
      'description': 'Trip ended',
    })
  }
];
