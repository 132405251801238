import { css } from '@linaria/core';

export const radial = css`
  width: 1200px;
  height: 1200px;
  border-radius: 50%;
  background: -webkit-radial-gradient(farthest-side at 50% 50%, #48138b, rgba(72, 19, 139, 0));
  background: radial-gradient(farthest-side at 50% 50%, #48138b, rgba(72, 19, 139, 0));
  position: absolute;
  border: 1px solid #15286b;
  z-index: -9999;

  &:before {
    content: " ";
    position: absolute;
    top: 17.5%;
    left: 17.5%;
    width: 65%;
    height: 65%;
    border: inherit;
    border-radius: 50%;
  }
`;

export const radialBanner = css`
  width: 1200px;
  height: 1200px;
  max-width: 100vw;
  max-height: 100vh;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
`;

export const radialFeatures = css`
  position: absolute;
  top: 100px;
  right: 0;
  transform: translateX(50%);
`;

export const radialBlog = css`
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
`;
