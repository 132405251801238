import { Tier } from '../../types';
import { getAllTiers as getAllTiersApi, promisify } from './contract-api';
import { toJSonAsArray } from './converters';
import { fromCereCents } from './tools/api-tools';
import { isRawTier, RawTier } from '../../types/raw-tier';

export async function getAllTiers(): Promise<Tier[]> {
  return (await promisify(getAllTiersApi, undefined, '')
    .then(toJSonAsArray))
    .filter(isRawTier)
    .map((tier: RawTier) => ({
      num: tier.tierId,
      fee: fromCereCents(tier.tierFee),
      limits: {
        wcuPerMinute: tier.wcuPerMinute,
        rcuPerMinute: tier.rcuPerMinute,
        storage: tier.storageBytes,
      },
    }));
}
