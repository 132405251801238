import { hexToU8a as hexToU8aPl, hexHasPrefix, hexAddPrefix } from '@polkadot/util';
import { PlainObject } from '../../types';

export function hexToU8a(hexString: string, bitLength?: number): Uint8Array {
  if (!hexHasPrefix(hexString)) {
    return hexToU8aPl(hexAddPrefix(hexString), bitLength);
  }
  return hexToU8aPl(hexString, bitLength);
}

export function combine(...args: Uint8Array[]): Uint8Array {
  return Uint8Array.from(
    args.reduce((acc: number[], el) => acc.concat(Array.from(el)), []),
  );
}

export function parseJson(data: string): string | PlainObject {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
}

export function getEmptyNonce(): Uint8Array {
  return new Uint8Array(24);
}

// Move `app_id` property in the array of objects to first position
export function appIdToTop(json: unknown): unknown {
  if (!Array.isArray(json)) {
    return json;
  }

  for (let i = 0; i < json.length; i += 1) {
    // eslint-disable-next-line camelcase
    const { app_id, ...rest } = json[i];
    // eslint-disable-next-line no-param-reassign
    json[i] = {};
    // eslint-disable-next-line camelcase
    if (app_id) {
      // eslint-disable-next-line camelcase, no-param-reassign
      json[i] = { app_id };
    }
    // eslint-disable-next-line no-param-reassign
    json[i] = { ...json[i], ...rest };
  }

  return json;
}
