import { ContractCallOutcome } from '@polkadot/api-contract/types';
import { AnyJson } from '@polkadot/types/types';

export const toJSon = (result: ContractCallOutcome | undefined): AnyJson => result?.output?.toJSON();
export const toJSonAsArray = (result: ContractCallOutcome | undefined): unknown[] => {
  const arr = result?.output?.toJSON();
  if (Array.isArray(arr)) {
    return arr;
  }
  return [];
};

export const toNumberArray = (result: ContractCallOutcome | undefined): number[] => toJSonAsArray(result).map(Number);

const toUnknownArray = (maybeArray: unknown): unknown[] => {
  if (Array.isArray(maybeArray)) {
    return maybeArray;
  }
  return [];
};

export { toUnknownArray };
