import { web3FromSource } from '@polkadot/extension-dapp';
import { ISubmittableResult } from '@polkadot/types/types/extrinsic';
import { ContractTxResult } from './types';
import { getPolkadotContract } from '../tools/api-tools';
import { getAccount } from '../tools/get-account';

export async function* unsubscribe(userAddress: string): ContractTxResult {
  yield { log: '- Looking for account...' };
  const account = await getAccount(userAddress);
  yield { log: '+ Account found' };
  yield { log: '- Connecting to the contract...' };
  const contract = await getPolkadotContract();
  yield { log: '+ Connected' };
  yield { log: '- Executing unsubscribe...' };
  const { signer } = await web3FromSource(account.meta.source);
  const txResult: ISubmittableResult = await new Promise((resolve, reject) => {
    contract.tx
      .unsubscribe({ value: 0, gasLimit: -1 })
      .signAndSend(userAddress, { signer }, (result) => {
        if (result.isCompleted) {
          resolve((result as unknown) as ISubmittableResult);
        } else if (result.isError) {
          reject(result);
        }
      });
  });
  yield { result: txResult, log: '+ Executing unsubscribe completed' };
}
