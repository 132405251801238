import { useCallback, useState } from 'react';
import { useAbortSignal } from './use-abort-signal';

type StateUpdater<T> = (prevState: T) => T;
type SetValueType<T> = (update: T | StateUpdater<T>) => void;

export function useSafeState<T = unknown>(initialValue: T): [T, SetValueType<T>] {
  const [value, setValue] = useState(initialValue);
  const signal = useAbortSignal();
  const safeSetValue = useCallback((update: T | StateUpdater<T>) => {
    if (!signal.aborted) {
      setValue(update);
    }
  }, [signal]);
  return [value, safeSetValue];
}
