import { promisify, refund as refundApi } from './contract-api';
import { User } from '../../shared/provider';

type RefundParams = {
  user: User;
};

export async function refund({ user }: RefundParams): Promise<boolean> {
  const result = await promisify(refundApi, undefined, user);
  return result ? result.isCompleted : false;
}
