import { ReactElement, useCallback, useContext, useRef, useState } from 'react';
import { cx } from '@linaria/core';
import prettyBytes from 'pretty-bytes';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { btn, btnSmall } from '../../routes/home/buttons.styles';
import { col, row, tierDescription, tokenBox, tokenBoxHeader, tokenPrice } from './styles';
import { Tier } from '../../types';
import { AppContext, useIsAuthenticated } from '../provider';
import { OperationProgress } from '../operation-progress/operation-progress';
import { delay } from '../../lib/delay';
import { Subscriber } from '../../lib/polkadot-api/contract-api/types';
import { getCompletedStepLog, getCurrentStepLog, subscribe } from '../../lib/polkadot-api';

export type TiersListType = {
  back?: () => void;
}

export function TiersList({ back }: TiersListType): ReactElement {
  const topSectionRef = useRef<HTMLElement>(null);
  const history = useHistory();
  const matchToAccountPage = useRouteMatch({ path: '/account', exact: true });
  const { user, updateSubscription, tiers, token, ddcClient } = useContext(AppContext);
  const isAuthenticated = useIsAuthenticated();
  const [subscriptionInProgress, setSubscription] = useState<Tier | null>(null);
  const [current, setCurrent] = useState<string[]>([]);
  const [completed, setCompleted] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const subscriber: Subscriber = useCallback((log: string | null, err) => {
    if (err) {
      setErrors((errorLogs) => errorLogs.concat(err.message));
    } else {
      const currentLog = getCurrentStepLog(log);
      const completedLog = getCompletedStepLog(log);
      if (completedLog) {
        setCompleted((logs) => logs.concat(completedLog));
      } else if (currentLog) {
        setCurrent((logs) => logs.concat(currentLog));
      }
    }
  }, []);

  const resetLog = useCallback(() => {
    setCurrent([]);
    setCompleted([]);
    setErrors([]);
  }, [setCompleted, setCurrent, setErrors]);

  const subscribeCallback = useCallback(async (tier) => {
    if (!user) {
      return;
    }
    resetLog();
    const topSection = topSectionRef?.current;
    setSubscription(tier);
    if (topSection) {
      topSection.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
    try {
      await subscribe({ user, tier, subscriber, ddcClient });
    } catch (e) {
      await delay(10000);
    }
    setSubscription(null);
    if (back) {
      back();
    }
    await updateSubscription(user.address);
    if (!matchToAccountPage) {
      history.push('/account');
    }
  }, [ddcClient, history, matchToAccountPage, resetLog, subscriber, updateSubscription, user, back]);

  return (
    <section ref={topSectionRef}>
      {subscriptionInProgress && (
        <OperationProgress
          header={`Subscribing to Tier ${subscriptionInProgress.num}`}
          errors={errors}
          current={current}
          completed={completed}
        />
      )}
      {!subscriptionInProgress && (
        <div className="pt-24 pb-20 text-center">
          {back && <h3 className={cx('text-left')}><button onClick={back} type="button"> &lt; Back</button></h3>}
          <div className={cx(row, 'flex flex-row flex-wrap')}>
            {tiers.map((tier) => (
              <div key={tier.num} className={col}>
                <div className={cx(tokenBox, 'mb-16')}>
                  <h4 className={cx(tokenBoxHeader, 'font-header')}>Tier {tier.num}</h4>
                  <div className={tokenPrice}>
                    <span className={cx(tierDescription, 'font-header block text-white text-nowrap overflow-hidden')}>
                      {tier.fee}&nbsp;{token}/Month
                      <br />
                      {prettyBytes(tier.limits.storage, { maximumFractionDigits: 0 })}
                      {' '}
                      Storage
                    </span>
                    <span className="block" />
                  </div>
                  {isAuthenticated && (
                    <button onClick={() => subscribeCallback(tier)} className={cx(btn, btnSmall)} type="button">
                      Subscribe
                    </button>
                  )}
                  {!isAuthenticated && (
                    <Link to="/login" className={cx(btn, btnSmall)}>Sign up</Link>
                  )}
                </div>
              </div>
            ))}
          </div>
          { process.env.ENV !== 'mainnet' ? (
            <p>
              Note: These Tiers are for demonstration purposes only during the Cere testnet phase and will use
              test tokens. Use&nbsp;
              <a href="https://laboratory.cere.network/#/friend-bot" target="_blank" rel="noreferrer">
                Faucet
              </a> to transfer CERE tokens to your wallet.
            </p>
          ) : ''}

        </div>
      )}
    </section>
  );
}
