import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';

export type AccountFieldType = {
  label: string;
  name: string;
  value?: string|number|null;
  onChange?: (value: string) => void;
  fieldType?: 'input' | 'textarea';
  children?: ReactNode;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
  monospaced?: boolean;
  height?: number;
}

const formLine = css`
  grid-template-columns: 1fr 125px;
  align-items: end;
`;

const Label = styled.label<{disabled: boolean}>`
  color: var(--cyan);
  font-size: 1.4rem;
  line-height: 2.5rem;
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};
  width: 100%;

  &:after {
    content: " ";
    position: absolute;
    bottom: -.3rem;
    left: 0;
    width: 100%;
    height: 1px;
    display: block;
    opacity: 1;
    background: var(--cyan);
    transition: all .3s;
  }
`;

const Input = styled.input<{ monospaced: boolean }>`
  outline: none;
  border: none;
  font-size: 1.6rem;
  background: transparent;
  font-family: ${({ monospaced }) => (monospaced ? '\'Roboto Mono\', \'Courier New\', monospace' : 'inherit')};
  font-weight: ${({ monospaced }) => (monospaced ? 700 : 'inherit')};
`;

const Textarea = styled.textarea<{ monospaced: boolean; height?: number }>`
  outline: none;
  border: none;
  font-size: 1.6rem;
  height: ${({ height }) => (height ? `${height}px` : 'inherit')};
  background: transparent;
  font-family: ${({ monospaced }) => (monospaced ? '\'Roboto Mono\', \'Courier New\', monospace' : 'inherit')};
  font-weight: ${({ monospaced }) => (monospaced ? 700 : 'inherit')};
  min-height: 1.5rem;
`;

export function AccountField({
  label,
  name,
  value,
  fieldType = 'input',
  children,
  height = 0,
  onChange = () => undefined,
  readonly = false,
  required = false,
  disabled = false,
  monospaced = false,
}: AccountFieldType): ReactElement {
  const [fieldValue, setFieldValue] = useState(value || '');

  useEffect(() => {
    setFieldValue(value || '');
  }, [value]);

  const change = useCallback((e) => {
    const val = e.target.value ?? '';
    onChange(val);
    setFieldValue(val);
  }, [onChange]);

  return (
    <div className={cx(formLine, 'grid gap-x-10 mb-12')}>
      <Label disabled={disabled} className={cx('relative flex flex-col')}>
        {label}
        {fieldType === 'input' && (
          <Input
            disabled={disabled}
            required={required}
            onChange={change}
            readOnly={readonly}
            className="text-white"
            type="text"
            name={name}
            value={fieldValue}
            monospaced={monospaced}
          />
        )}
        {fieldType === 'textarea' && (
          <Textarea
            disabled={disabled}
            required={required}
            onChange={change}
            readOnly={readonly}
            height={height}
            className="text-white"
            name={name}
            value={fieldValue}
            monospaced={monospaced}
          />
        )}
      </Label>
      {children || <div />}
    </div>
  );
}
