import { ReactElement, ReactNode } from 'react';
import { styled } from '@linaria/react';

export type ContainerType = {
  children?: ReactNode;
}

const Div = styled.div`
  width: var(--container);
`;

export function Container({ children }: ContainerType): ReactElement {
  return (
    <Div className="mx-auto px-4">{children}</Div>
  );
}
