/* eslint-disable camelcase */
import { ContractCallOutcome } from '@polkadot/api-contract/types';
import { Subscription } from '../../shared/provider';
import { metricsSinceSubscription, promisify, tierIdOf, tierLimitOf } from './contract-api';
import { toJSon } from './converters';
import { DdcClient } from '../ddc/ddc-client';
import { getPrincipal } from '../get-principial';
import { isValidMetrics } from '../../types/metrics-raw';
import { isTierLimit } from '../../types/tier-limit-raw';
import { getSubscriptionDetails } from './contract-api/get-subscription-details';
import { isSubscriptionDetails } from '../../types/subscription-details-raw';

const verifyContractOutCome = (result: ContractCallOutcome | undefined) => {
  if (!(result?.output as any)?.isOk) {
    throw Error(JSON.stringify(result?.output?.toJSON()));
  }
}

export async function getSubscription(userAddress: string, ddcClient: DdcClient): Promise<Subscription | null> {
  try {
    const [subscriptionDetails, metrics, tierLimit, tierId, isRegisteredInDdc] = await Promise.all([
      promisify(getSubscriptionDetails, undefined, userAddress).then((result) => {
        verifyContractOutCome(result);
        const response = result?.output?.toJSON();
        if (isSubscriptionDetails(response)) {
          return response;
        }
        throw Error(`Bad Subscription Details response ${JSON.stringify(response)}`);
      }),
      promisify(metricsSinceSubscription, undefined, userAddress).then((result) => {
        verifyContractOutCome(result);
        const response = result?.output?.toJSON();
        if (isValidMetrics(response)) {
          return response;
        }
        throw Error(`Bad metrics response ${JSON.stringify(response)}`);
      }),
      promisify(tierLimitOf, undefined, userAddress).then(toJSon).then((result) => {
        if (isTierLimit(result)) {
          return result;
        }
        throw Error(`Bad Tier Limit response ${JSON.stringify(result)}`);
      }),
      promisify(tierIdOf, undefined, userAddress).then((result) => Number(result?.output?.toString())),
      ddcClient.isAppRegistered(getPrincipal(userAddress)),
    ]);

    return isRegisteredInDdc
      ? {
        tier: tierId,
        metrics: {
          storageBytes: metrics.ok.storageBytes,
          wcuUsed: metrics.ok.wcuUsed,
          rcuUsed: metrics.ok.rcuUsed,
        },
        limits: {
          storageBytes: tierLimit.storageBytes,
          wcuPerMinute: tierLimit.wcuPerMinute,
          rcuPerMinute: tierLimit.rcuPerMinute,
        },
        subscriptionDetails: {
          endDateMs: subscriptionDetails.ok.endDateMs,
          balance: subscriptionDetails.ok.subscription.balance,
          lastUpdateMs: subscriptionDetails.ok.subscription.lastUpdateMs,
          startDateMs: subscriptionDetails.ok.subscription.startDateMs,
        },
      }
      : null;
  } catch (e) {
    return null;
  }
}
