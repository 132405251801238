import jp from 'jsonpath';
import { JSON_ROOT_PATH } from './const';
import { parseJson } from './utils';
import { CryptoSecretKey } from './crypto-secret-key';

export class ScopedCryptoSecretKeys {
  constructor(public pathToDecryptToDecryptionKeyHex: Map<string, string>) {}

  decryptWithScopes(data: string): string {
    const result = parseJson(data);

    if (typeof result === 'string') {
      return CryptoSecretKey.fromHexString(this.pathToDecryptToDecryptionKeyHex.get(JSON_ROOT_PATH) ?? '')
        .decryptDirectly(data);
    }

    Array.from(this.pathToDecryptToDecryptionKeyHex.entries()).forEach(([path, hexKey]) => {
      const matches = jp.paths(result, path);
      matches.forEach((match) => {
        const matchPath = jp.stringify(match);
        const dec = CryptoSecretKey.fromHexString(hexKey);
        const [value] = jp.query(result, matchPath) as string[];
        jp.apply(result, matchPath, () => dec.decryptDirectly(value.toString()));
      });
    });

    return JSON.stringify(result);
  }

  static fromRootKeyHex(rootKeyHex: string): ScopedCryptoSecretKeys {
    return new ScopedCryptoSecretKeys(new Map([[JSON_ROOT_PATH, rootKeyHex]]));
  }
}
