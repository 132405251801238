/* eslint-disable class-methods-use-this */
import { blake2AsU8a, naclDecrypt, naclEncrypt } from '@polkadot/util-crypto';
import { stringToU8a, u8aToHex, u8aToString } from '@polkadot/util';
import jp from 'jsonpath';

import { Key } from './key';
import { combine, hexToU8a, parseJson } from './utils';
import { ALL_PATHS, PATH_SEPARATOR, JSON_ROOT_PATH } from './const';
import { ScopedCryptoSecretKeys } from './scoped-secret-keys';
import { isNumber, isString } from '../../types';
import { isBoolean } from '../../types/is-boolean';
import { EncryptionResult } from './types';

export class CryptoSecretKey extends Key {
  static fromHexString(hexString: string): CryptoSecretKey {
    return new CryptoSecretKey(hexToU8a(hexString));
  }

  static fromString(secret: string): CryptoSecretKey {
    return new CryptoSecretKey(stringToU8a(secret));
  }

  encryptWithScopes(data: string, jsonPathsToEncrypt: string[] = [ALL_PATHS]): EncryptionResult {
    const pathsToKeys = new Map<string, string>();
    const result = parseJson(data);

    if (['string', 'number'].includes(typeof result)) {
      const dec = this.derive(JSON_ROOT_PATH);
      pathsToKeys.set(JSON_ROOT_PATH, dec.toHex());
      return {
        encryptedData: dec.encryptDirectly(data),
        scopedCryptoSecretKeys: new ScopedCryptoSecretKeys(pathsToKeys),
      };
    }

    jsonPathsToEncrypt.forEach((path) => {
      const matches = jp.paths(result, path);
      matches.forEach((match) => {
        const matchPath = jp.stringify(match);
        const dec = this.derive(matchPath);
        pathsToKeys.set(matchPath, dec.toHex());
        const [value] = jp.query(result, matchPath) as unknown[];
        if (isString(value) || isNumber(value) || isBoolean(value)) {
          jp.apply(result, matchPath, () => dec.encryptDirectly(value.toString()));
        }
      });
    });
    return { encryptedData: JSON.stringify(result), scopedCryptoSecretKeys: new ScopedCryptoSecretKeys(pathsToKeys) };
  }

  decryptWithScopes(data: string, jsonPathsToEncrypt: string[] = [ALL_PATHS]): string {
    const result = parseJson(data);

    if (typeof result === 'string') {
      return this.derive(JSON_ROOT_PATH).decryptDirectly(data);
    }
    jsonPathsToEncrypt.forEach((path) => {
      const matches = jp.paths(result, path);
      matches.forEach((match) => {
        const matchPath = jp.stringify(match);
        const dec = this.derive(matchPath);
        const [value] = jp.query(result, matchPath) as string[];
        if (isString(value)) {
          jp.apply(result, matchPath, () => dec.decryptDirectly(value.toString()));
        }
      });
    });
    return JSON.stringify(result);
  }

  private encryptDirectly(message: string): string {
    return u8aToHex(naclEncrypt(stringToU8a(message), this.keyBytes, new Uint8Array(24)).encrypted);
  }

  decryptDirectly(messageHex: string): string {
    return u8aToString(naclDecrypt(hexToU8a(messageHex), new Uint8Array(24), this.keyBytes));
  }

  derive(path: string): CryptoSecretKey {
    const derived = combine(this.keyBytes, stringToU8a(PATH_SEPARATOR), stringToU8a(path));
    return new CryptoSecretKey(blake2AsU8a(derived, 256));
  }
}
