import { css } from '@linaria/core';

export const btn = css`
  --radius: 25px;

  height: 5rem;
  display: inline-flex;
  font-size: 1.8rem;
  line-height: 5rem;
  color: #fff;
  font-weight: 500;
  background-color: #4039d4;
  border: none;
  padding: 0 4rem;
  border-radius: var(--radius);
  outline: none;
  transition: all .5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    display: block;
    background-color: var(--cyan);
    opacity: 0;
    z-index: -1;
    border-radius: var(--radius);
    transition: all .3s;
  }

  &:hover {
    color: #fff;
    background-color: var(--cyan);
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.8);
  }

  &:hover::before {
    content: " ";
    width: 100%;
    opacity: 1;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: .65;
  }
`;

export const btnSmall = css`
  height: 4rem;
  font-size: 1.6rem;
  line-height: 4rem;
  padding: 0 3rem;

  &:hover {
    color: #fff;
  }
`;

export const btnLink = css`
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--cyan);
  font-weight: 500;
  position: relative;

  i {
    font-size: 1.5rem;
    vertical-align: middle;
    transition: all .3s;
  }

  &:after {
    content: " ";
    height: 1px;
    display: block;
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 20px;
    background-color: var(--cyan);
  }

  &:hover {
    color: var(--cyan);
    text-decoration: none;

    i {
      padding-left: 3px;
    }
  }
`;
