import { isRecord, Tier } from '../../types';
import { DdcClient } from '../../lib/ddc/ddc-client';
import { DavinciClient } from '../../lib/davinci/davinci-client';
import { EventService } from '../../lib/event-service';

export type Subscription = {
  tier: number,
  metrics: {
    storageBytes: number;
    wcuUsed: number;
    rcuUsed: number;
  };
  limits: {
    storageBytes: number;
    wcuPerMinute: number;
    rcuPerMinute: number;
  };
  subscriptionDetails: {
    endDateMs: number;
    balance: number;
    lastUpdateMs: number;
    startDateMs: number;
  }
};

export type User = {
  name: string;
  address: string;
  principal: string;
  subscription?: Subscription | null;
};

export type UserSession = Omit<User, 'principal'>;

export function isUserSession(val: unknown): val is UserSession {
  return isRecord(val)
    && typeof val.name === 'string'
    && val.name !== ''
    && typeof val.address === 'string'
    && val.address !== '';
}

export type AppContextType = {
  login: (user?: Omit<User, 'principal'>) => void;
  logout: () => void;
  updateSubscription: (address?: string) => Promise<unknown>;
  user: User | null;
  tiers: Tier[];
  token: string;
  ddcClient: DdcClient,
  davinciClient: DavinciClient,
  eventService: EventService
};

