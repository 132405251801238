import { css } from '@linaria/core';

export const header = css`
  font-size: 5rem;
  line-height: 6.6rem;
  margin-bottom: 1.9rem;
`;

export const text = css`
  color: #b492e9;
  font-weight: 400;
`;

export const offerHeader = css`
  font-size: 4rem;
  line-height: 5.6rem;
  margin-bottom: 2.2rem;
`;

export const offerText = css`
  font-size: 1.8rem;
  line-height: 28px;
  color: #b492e9;
  font-weight: 400;
  margin-bottom: 2.4rem;

  strong {
    font-weight: 700;
  }
`;

export const centerText = css`
  text-align: center;
`;
