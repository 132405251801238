enum SortOrder {
  Desc = 'Desc',
  Asc = 'Asc',
}

type IdentityFn<T> = (item: T) => string|number;

export function sortBy<T extends Record<number | string | symbol, unknown>>(
  collection: T[],
  identity: IdentityFn<T>,
  order: SortOrder = SortOrder.Asc,
): T[] {
  const sortedCollection: T[] = collection.slice();
  sortedCollection.sort((a, b) => {
    if (order === SortOrder.Asc) {
      return (identity(a) > identity(b) ? 1 : -1);
    }
    return (identity(a) > identity(b) ? -1 : 1);
  });
  return sortedCollection;
}

export function sortedByDescending<T extends Record<number | string | symbol, unknown>>(
  collection: T[],
  identity: IdentityFn<T>,
): T[] {
  return sortBy(collection, identity, SortOrder.Desc);
}
export function sortedByAscending<T extends Record<number | string | symbol, unknown>>(
  collection: T[],
  identity: IdentityFn<T>,
): T[] {
  return sortBy(collection, identity, SortOrder.Asc);
}
