import { ApiPromise, WsProvider } from '@polkadot/api';
import { ContractPromise } from '@polkadot/api-contract';
import { web3Enable } from '@polkadot/extension-dapp';

import chainConfig from '../chain-config.json';
import ddcContractAbi from '../ddc-contract-abi.json';
import { getBlockchainConfig } from './api-tools';
import { BlockchainConfig } from '../../../types';

class PolkadotTools {
  public readonly blockchainConfig: BlockchainConfig;

  private apiPromise: ApiPromise | undefined;

  private contract: ContractPromise | undefined;

  constructor() {
    this.blockchainConfig = getBlockchainConfig();
    web3Enable('DDC').catch(() => null);
  }

  async getPolkadotApi({ forceLoadApi = false } = {}): Promise<ApiPromise> {
    if (this.apiPromise && !forceLoadApi) {
      return this.apiPromise;
    }
    this.apiPromise = await ApiPromise.create({
      provider: new WsProvider(this.blockchainConfig['ws-url']),
      types: chainConfig,
    });
    await this.apiPromise.isReady;
    await this.apiPromise.rpc.system.chain();
    return this.apiPromise;
  }

  async getPolkadotContract({
    forceLoadApi = false,
    forceLoadContract = false,
  } = {}): Promise<ContractPromise> {
    const apiPromise = await this.getPolkadotApi({ forceLoadApi });
    if (this.contract && !forceLoadContract) {
      return this.contract;
    }
    this.contract = new ContractPromise(apiPromise, ddcContractAbi, this.blockchainConfig.contract.address);
    return this.contract;
  }
}

export const polkadotTools = new PolkadotTools();
