import { ReactElement, useMemo } from 'react';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';

export type ProgressType = {
  value: number;
}

const wrapper = css`
  --radius: 1rem;

  width: 100%;
  height: 2rem;
  background-color: #2b0c65;
  border-radius: var(--radius);
  margin-bottom: 1.8rem;
`;

const ProgressBar = styled.div<{width: number}>`
  width: ${({ width }) => width}%;
  background-color: var(--cyan);
  border-radius: inherit;
  transition: all 1s !important;
`;

export function Progress({ value = 0 }: ProgressType): ReactElement {
  const normalizedValue = useMemo(() => Math.min(100, value), [value]);
  return (
    <div className={cx(wrapper, 'flex items-stretch')}><ProgressBar width={normalizedValue} /></div>
  );
}
