import { ReactElement, useContext } from 'react';
import { css, cx } from '@linaria/core';
import useSwr from 'swr';

import { bannerWrapper, header } from '../login/login.styles';
import { Container } from '../../layout/container';
import { radial, radialBlog } from '../../app.styles';
import { row } from '../../shared/tiers-list/styles';
import { AssetDdc, Token } from '../../types';
import { AppContext } from '../../shared/provider';
import { NftAssetContent } from './nft-asset-content';

const table = css`
  grid-template-columns: 1fr;
  align-items: start;
`;

type NftAssetsProps = {
  nft: Token;
  cids: string[];
  onBack: () => void;
};

export function NftAssets({ nft, onBack, cids }: NftAssetsProps): ReactElement {
  const { davinciClient } = useContext(AppContext);
  const { data: assetsMetadata, error } = useSwr<{ [key: string]: AssetDdc }, Error>(
    [nft.minter, cids],
    davinciClient.getCidsMetadata,
  );
  const assets = assetsMetadata ? cids : [];
  const fetchContentError = error?.message;

  return (
    <>
      <section>
        <div className={cx(bannerWrapper, 'flex items-center')}>
          <Container>
            <div className="banner-txt">
              <h1 className={cx(header, 'font-header')}>Asset Viewer</h1>
            </div>
          </Container>
        </div>
      </section>
      <section>
        <div className={cx(radial, radialBlog)} />
        <Container>
          <div className={cx(table, 'grid gap-x-10 gap-y-6 py-20')}>
            {onBack && (
              <h3 className={cx('text-left')}>
                <button onClick={onBack} type="button">
                  {' '}
                  &lt; Back
                </button>
              </h3>
            )}
            <div className="text-left">
              {fetchContentError && <h3 className="text-3xl mb-0 mt-12 text-red-300">{fetchContentError}</h3>}
            </div>
            <div className={cx(row, 'flex flex-row flex-wrap')}>
              {nft && cids.length === 0 ? (
                <div className={cx('my-4 pl-2')}>
                  <h3 className="text-3xl mb-0 mt-12 text-red-300">No assets for the selected NFT token</h3>
                </div>
              ) : null}
              {assets.map((cid) => (
                assetsMetadata?.[cid] ? (
                  <NftAssetContent key={cid} metadata={assetsMetadata?.[cid]} minter={nft.minter} cid={cid} />
                ) : null
              ))}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
