import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { cx } from '@linaria/core';
import { counter, fadeInOut, preloader } from './loader.styles';

export type LoaderType = {
  children: ReactNode;
  error?: string;
  completed?: boolean;
};

const INCREMENT_STEP = 4;

export function Loader({ children, completed = false, error }: LoaderType): ReactElement {
  const [count, setCount] = useState(0);
  const counterRef = useRef<HTMLDivElement>(null);
  const preloaderRef = useRef<HTMLDivElement>(null);
  const interval = useRef(0);
  const [done, setDone] = useState(false);

  const increment = useCallback(
    () => setCount((currentCount) => {
      const currentCounter = Math.min(currentCount + INCREMENT_STEP, 100);
      if (currentCounter >= 100) {
        window.clearInterval(interval.current);
      }
      return currentCounter;
    }),
    [],
  );

  const animationHandler = useCallback(() => {
    setDone(true);
  }, []);

  useEffect(() => {
    interval.current = window.setInterval(increment, 100);
    return () => window.clearInterval(interval.current);
  }, [count, increment]);

  useEffect(() => {
    const preloaderEl = preloaderRef.current;
    preloaderEl?.addEventListener('animationend', animationHandler);
    return () => preloaderEl?.removeEventListener?.('animationend', animationHandler);
  }, [animationHandler]);

  return (
    <>
      {(!done || error) && (
        <div
          ref={preloaderRef}
          className={cx(
            preloader,
            completed && !error && 'open-page',
            !completed && !error && count >= 100 && fadeInOut,
            'flex items-center justify-center',
          )}
        >
          <div ref={counterRef} className={cx(counter, 'relative')}>
            {
              error
                ? (
                  <div className="text-3xl">
                    <div className="text-white text-5xl">
                      An error occurred while loading the page. Try to reload to continue.
                    </div>
                    <div className="mt-4 text-red-400">Error output:</div>
                    <div className="mt-2 text-red-400">{error}</div>
                  </div>
                )
                : `${count}%`
            }
          </div>
        </div>
      )}
      {completed && (!error && children)}
    </>
  );
}
