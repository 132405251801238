import { css } from '@linaria/core';

export const bannerWrapper = css`
  padding: 4.7rem 0;
  background-image: linear-gradient(to right, rgba(73, 19, 140, 0.5) 0%, rgba(52, 20, 119, 0.5) 100%);
  background-repeat: repeat-x;
`;

export const header = css`
  font-size: 5rem;
  line-height: 6.6rem;
  color: #fff;
  margin-bottom: 0;
`;

export const tokenForm = css`
  padding: 4.3rem 4rem 5rem;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(135deg, #49138c, #341477);
  background-repeat: no-repeat;
  transition: all .5s;
`;

export const header3 = css`
  --magenta: #f8a4d8;
  --blue: #6a63ff;
  padding-bottom: 2rem;
  padding-left: 4.5rem;
  margin-bottom: 4rem;
  position: relative;
  font-size: 3rem;
  line-height: 4.2rem;

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: linear-gradient(135.68deg, var(--magenta) 0%, var(--blue) 100%);
  }

  i {
    position: absolute;
    top: 2px;
    left: 0;
    color: var(--magenta);
    background: linear-gradient(135deg, var(--magenta) 0%, var(--blue) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
  }
`;

export const select = css`
  font-size: 1.6rem;
  height: 40px;
  color: #fff;
  padding: 0 1.6rem;
  border: 2px solid #4039d4;
  background: transparent;
  border-radius: 1rem;
  outline: none;
  box-shadow: none;
`;
