import { ReactElement, useMemo } from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { zip } from '../../lib/zip';

export type ProgressType = {
  header?: string;
  errors?: string[];
  current?: string[];
  completed?: string[];
  justify?: 'left' | 'center';
}

const blue = css`
  color: var(--cyan);
`;

const Wrapper = styled.div<{ justify: 'left' | 'center' }>`
  align-items: ${({ justify }) => (justify === 'center' ? 'center' : 'flex-start')};
`;

export function OperationProgress({
  header,
  current = [],
  completed = [],
  errors = [],
  justify = 'center',
}: ProgressType): ReactElement {
  const logs = useMemo(() => zip(current, completed), [completed, current]);

  return (
    <Wrapper justify={justify} className="flex flex-col items-center">
      {header && (
        <h3 className={blue}>
          <i className="icon-games" /> {header}
        </h3>
      )}
      {logs.map(([currentStep, completedStep]) => {
        const stepText = completedStep || currentStep;
        return (
          <h4 title={stepText} key={stepText} className={blue}>
            {completedStep ? <i className="icon-check-3" /> : <i className="icon-alarm-1" />} {stepText}
          </h4>
        );
      })}
      {errors.map((error) => (
        <h4 title={error} key={error} className="text-red-200">
          <i className="icon-error-3" />
          {' '}
          {error}
        </h4>
      ))}
    </Wrapper>
  );
}
