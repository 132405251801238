import { ReactElement, useCallback, useContext } from 'react';
import { cx } from '@linaria/core';
import useSwr from 'swr';

import { Token } from '../../types';
import { btn, btnSmall } from '../home/buttons.styles';
import { col, tierDescription, tokenBox, tokenBoxHeader, tokenPrice } from '../../shared/tiers-list/styles';
import { cropNftAddress } from './utils';
import { AppContext } from '../../shared/provider';

type Props = {
  showAssetsForNft: (token: Token, cid: string[]) => unknown;
  token: Token;
};

export function NftTokenCard({ token, showAssetsForNft }: Props): ReactElement {
  const { davinciClient } = useContext(AppContext);
  const { data: cids } = useSwr<string[], Error>([token.nftId], davinciClient.getNftCids, { fallbackData: [] });
  const showAssets = useCallback(() => {
    if (cids && cids.length > 0) {
      showAssetsForNft(token, cids);
    }
  }, [cids, showAssetsForNft, token]);
  return (
    <div className={col} key={token.nftId}>
      <div className={cx(tokenBox, 'mb-16')}>
        <h4 className={cx(tokenBoxHeader, 'font-header')}>{cropNftAddress(token.nftId)}</h4>
        <div className={tokenPrice}>
          <span className={cx(tierDescription, 'font-header block text-white text-nowrap overflow-hidden')}>
            Assets: {cids?.length ?? ''}
          </span>
          <span className="block">
            Amount: {token.quantity} / {token.supply}
          </span>
        </div>
        <button disabled={!cids || cids?.length <= 0} onClick={showAssets} className={cx(btn, btnSmall)} type="button">
          Show Assets
        </button>
      </div>
    </div>
  );
}
