import { getPolkadotContract } from '../tools/api-tools';
import { ContractQueryResult } from './types';

export async function* getAllTiers(userAddress: string): ContractQueryResult {
  yield { log: '- Connecting to the contract...' };
  const contract = await getPolkadotContract();
  yield { log: '+ Connected' };
  yield { log: '- Executing get_all_tiers...' };
  const response = await contract.query.getAllTiers(userAddress, {
    storageDepositLimit: null,
    gasLimit: -1,
  });
  yield {
    result: response,
    log: '+ Executing get_all_tiers completed',
  };
}
