import { web3FromSource } from '@polkadot/extension-dapp';
import { stringToHex } from '@polkadot/util';
import { ResultWithLog, Subscriber } from './contract-api/types';
import { DdcClient } from '../ddc/ddc-client';
import { getAccount } from './tools/get-account';
import { User } from '../../shared/provider';
import { promisify } from './contract-api';
import { CryptoSecretKey } from '../crypto-v1';

type SendDataRequest = {
  id: string;
  userPubKey: string;
  timestamp: string;
  data: string;
  encryptionKey: string;
  paths: string[];
  isEncrypted: boolean;
};

async function* sendDataGenerator(
  user: User,
  {
    id,
    userPubKey,
    timestamp,
    data,
    isEncrypted,
    encryptionKey,
    paths,
  }: SendDataRequest,
  ddcClient: DdcClient,
): AsyncGenerator<ResultWithLog<string>> {
  let encryptedData;
  if (isEncrypted) {
    yield ({ log: '- Encrypting data...' });
    const masterSecretKey = CryptoSecretKey.fromString(encryptionKey);
    encryptedData = masterSecretKey.encryptWithScopes(data, paths).encryptedData;
    yield ({ log: '+ Encrypted data...' });
  } else {
    encryptedData = data;
  }

  yield ({ log: '- Looking for account' });
  const account = await getAccount(user.address);
  yield ({ log: '+ Account found' });

  yield ({ log: '- Signing request...' });
  const injector = await web3FromSource(account.meta.source);
  const { signRaw } = injector.signer;
  const signatureResponse = await signRaw?.({
    address: account?.address ?? '',
    data: stringToHex(id + timestamp + user.principal + userPubKey + encryptedData),
    type: 'bytes',
  });
  yield ({ log: '+ Signed request' });

  yield ({ log: '- Sending to DDC...' });
  const { cid } = await ddcClient.save({
    appPubKey: user.principal,
    userPubKey,
    id,
    timestamp,
    data: encryptedData,
    signature: signatureResponse?.signature?.replace?.('0x', '') ?? '',
  }, { isEncrypted }).catch((err) => {
    try {
      const errDetails = JSON.parse(err.message);
      const body = JSON.parse(errDetails?.body);
      return Promise.reject(body.message ? Error(body.message) : err);
    } catch (e) {
      throw err;
    }
  });
  yield ({ log: '+ Sent to DDC' });

  yield ({ log: `+ Transmitted data to DDC, piece cid ${cid}` });
  yield ({ log: '+ Done', result: cid });
}

type SendData = {
  user: User;
  request: SendDataRequest;
  ddcClient: DdcClient;
  subscriber?: Subscriber;
};

export async function sendData({
  user,
  request,
  ddcClient,
  subscriber,
}: SendData): Promise<string> {
  return promisify(sendDataGenerator, subscriber, user, request, ddcClient)
    .then((hash) => {
      if (!hash) {
        throw Error('Empty hash');
      }
      return hash;
    });
}
