import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { css, cx } from '@linaria/core';

import { AssetDdc } from '../../types';
import { col, tierDescription, tokenBox, tokenBoxHeader, tokenPrice } from '../../shared/tiers-list/styles';
import { btn, btnSmall } from '../home/buttons.styles';
import { Loader } from '../../shared/loader';
import { useSafeState } from '../../lib/hooks';
import { AppContext } from '../../shared/provider';

type Props = {
  metadata: AssetDdc;
  minter: string;
  cid: string;
};

const paddingY = css`
  padding-top: 1em;
  padding-bottom: 1em;
`;

export function NftAssetContent({ cid, minter, metadata }: Props): ReactElement {
  const [content, setContent] = useSafeState<Blob | null>(null);
  const [progress, setProgress] = useSafeState(false);
  const [error, setError] = useSafeState<string | undefined>(undefined);
  const { davinciClient } = useContext(AppContext);

  const loadContent = useCallback(() => {
    setProgress(true);
    davinciClient
      .getCidContent({ minter, cid, ddcAddress: metadata.uploadedBy })
      .then((file) => {
        setError(undefined);
        setContent(file);
      })
      .catch(err => {
        setError(err.message);
      })
      .finally(() => setProgress(false));
  }, [cid, davinciClient, metadata.uploadedBy, minter, setContent, setError, setProgress]);

  const url = useMemo(() => (content ? URL.createObjectURL(content) : ''), [content]);
  const filename = content
    ? `${metadata.contentMetadata.title}.${metadata.contentMetadata.contentType.split('/').pop()}`
    : undefined;

  const assetContent = useMemo(() => {
    if (!content) {
      return null;
    }

    switch (metadata.contentMetadata.contentType) {
      case 'image/jpeg':
      case 'image/png':
        return <img alt="" className="img-fluid" src={url} />;
      case 'audio/mp4':
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio controls autoPlay>
            <source src={url} />
          </audio>
        );
      case 'video/mp4':
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls autoPlay>
            <source src={url} />
          </video>
        );
      default:
        return null;
    }
  }, [content, metadata.contentMetadata.contentType, url]);

  return (
    <div className={col} key={cid}>
      <div className={cx(tokenBox, 'mb-16')}>
        <h4 className={cx(tokenBoxHeader, 'font-header')}>{metadata.contentMetadata.title}</h4>
        <div className={tokenPrice}>
          <div title={metadata.contentMetadata.title} className={cx(tierDescription, 'font-header text-white')}>
            {metadata.contentMetadata.description}
          </div>
        </div>
        {
          error && (
            <div className={cx(paddingY, 'text-red-300', 'text-2xl', 'text-center', 'max-h-28')}>{error}</div>
          )
        }

        <span className={cx(paddingY, 'block')}>{assetContent}</span>
        {content ? (
          <a download={filename} href={url} target="_blank" rel="noreferrer noopener" className={cx(btn, btnSmall)}>
            Download
          </a>
        ) : (
          <button onClick={loadContent} className={cx(btn, btnSmall)} type="button" disabled={progress}>
            Show Content {progress && <Loader />}
          </button>
        )}
      </div>
    </div>
  );
}
