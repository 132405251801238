import { isRecord } from './is-record';

export type RawTier = {
  tierId: number,
  tierFee: number,
  storageBytes: number,
  wcuPerMinute: number,
  rcuPerMinute: number,
}

export const isRawTier = (item: unknown): item is RawTier => (
  isRecord(item) && item != null
  && item.tierId !== undefined
  && item.tierFee !== undefined
  && item.storageBytes !== undefined
  && item.wcuPerMinute !== undefined
  && item.rcuPerMinute !== undefined
);
