import { ReactElement, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../shared/provider';

export function Logout(): ReactElement {
  const { user, logout } = useContext(AppContext);

  useEffect(() => {
    logout();
  }, [logout]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return user ? <></> : <Redirect to="/" />;
}
