import { DdcClient, TESTNET, DEVNET, MAINNET, DagNodeUri } from '@cere-ddc-sdk/ddc-client';
import {Cid} from '@cere-ddc-sdk/ddc'
import { CereEtlServiceClient } from './cere-etl-service';

type Event = {
  timestamp: string
  account_id: string
  app_id: string
  payload: Record<string, unknown>
}

export class EventService {
  private readonly eventLinkCache: Map<string, [Event, string]> = new Map();

  private ddcClientPromise: Promise<DdcClient> | undefined;

  constructor(
    private readonly cereEtlServiceClient: CereEtlServiceClient,
    private readonly ddcAccountUrlOrSigner: string,
    private ddcEnvironment: string
  ) {}

  private async getOrCreateDdcClient () {
    if (this.ddcClientPromise) {
      return this.ddcClientPromise
    }

    const config = {DEVNET, TESTNET, MAINNET}[this.ddcEnvironment.toUpperCase()] || DEVNET

    this.ddcClientPromise = DdcClient.create(this.ddcAccountUrlOrSigner, config)

    return this.ddcClientPromise
  }

  async listEvents (appId: string, userPublicKey: string, limit = 50) {
    const rootPieceUri = await this.cereEtlServiceClient.fetchRootPieceUri(appId, userPublicKey)

    const [bucket, ...rootCidBase64EncodedArray] = rootPieceUri.split('/')

    const rootCidBase64Encoded = rootCidBase64EncodedArray.join('/')

    if (bucket == null || rootCidBase64Encoded == null || rootCidBase64EncodedArray.length === 0) {
      throw new Error(`Invalid root piece uri: ${rootPieceUri}`)
    }

    const bucketId = BigInt(bucket)

    const rootCid = new Cid(Uint8Array.from(atob(rootCidBase64Encoded), c => c.charCodeAt(0))).toString()

    let cidToRead = rootCid.toString() as string | undefined
    const events = [] as Event[]
    const readCids = new Set<string>()

    while (cidToRead) {
      let fetchResult
      try {
        // eslint-disable-next-line no-await-in-loop
        fetchResult = await this.readEvent(bucketId, cidToRead)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to fetch event with cid ${cidToRead}`, error)
        break
      }

      const {event, nextEventCid} = fetchResult

      events.push(event)

      if (events.length >= limit) {
        break
      }

      if(readCids.has(nextEventCid)) {
        // eslint-disable-next-line no-console
        console.error(`Loop detected: ${nextEventCid}`)
        break
      }

      readCids.add(cidToRead)
      cidToRead = nextEventCid
    }

    return events
  }

  private async readEvent(bucketId: bigint, cid: string) {
    const cached = this.eventLinkCache.get(cid)
    if (cached) {
      return {event: cached[0], nextEventCid: cached[1]}
    }

    const ddcClient = await this.getOrCreateDdcClient()

    const piece = await ddcClient.read(new DagNodeUri(bucketId, cid))

    const event = JSON.parse(piece.data.toString())

    if (!EventService.isEvent(event)) {
      throw new Error(`Invalid event: ${JSON.stringify(event)}`)
    }

    const nextEventCid: string | undefined = piece.links[0]?.cid

    this.eventLinkCache.set(cid, [event, nextEventCid])

    return {event, nextEventCid}
  }

  private static isEvent(value: unknown): value is Event {
    return typeof value === 'object'
      && value !== null
      && 'timestamp' in value
      && typeof value.timestamp === 'string'
      && 'account_id' in value
      && typeof value.account_id === 'string'
      && 'app_id' in value
      && typeof value.app_id === 'string'
      && 'payload' in value
      && typeof value.payload === 'object'
  }
}
