/* eslint-disable camelcase */

import { isRecord } from './index';

export type MetricsRaw = {
  ok: { storageBytes: number; wcuUsed: number, rcuUsed: number }
}

export const isValidMetrics = (val: unknown): val is MetricsRaw =>
  val != null && isRecord(val) && isRecord(val.ok)
  && typeof val.ok.storageBytes === 'number'
  && typeof val.ok.wcuUsed === 'number'
  && typeof val.ok.rcuUsed === 'number'
;
