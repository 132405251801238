import { ReactElement, useContext, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import prettyBytes from 'pretty-bytes';
import { css, cx } from '@linaria/core';

import { AppContext, useIsAuthenticated, useIsSubscribed } from '../../shared/provider';
import { bannerWrapper, header } from '../login/login.styles';
import { Container } from '../../layout/container';
import { radial, radialBlog } from '../../app.styles';
import { TiersList } from '../../shared/tiers-list/tiers-list';
import { tokenBox } from '../../shared/tiers-list/styles';
import { fromCereCents } from '../../lib/polkadot-api/tools/api-tools';
import { refund } from '../../lib/polkadot-api';
import { Progress } from './progress';
import { AccountSendForm } from './account-send-form';

const cyan = css`
  color: var(--cyan);
`;

const memoryFormat = (val: number): string => prettyBytes(val, { maximumFractionDigits: 0 });

export function Account(): ReactElement {
  const isAuthenticated = useIsAuthenticated();
  const isSubscribed = useIsSubscribed();
  const { user, updateSubscription } = useContext(AppContext);
  const subscription = user?.subscription;
  const [updateTier, setUpdateTier] = useState<boolean>(false);

  const storageUsageProgress = useMemo(() => {
    if (!subscription) {
      return 0;
    }
    return Math.round((100 * subscription.metrics.storageBytes) / subscription.limits.storageBytes);
  }, [subscription]);

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  const switchToAccountPage = () => {
    setUpdateTier(false);
  };

  const switchToTiersPage = () => {
    setUpdateTier(true);
  };

  const doRefund = async () => {
    if (!user) {
      throw new Error('User is not exists!');
    }

    await refund({ user });
    await updateSubscription(user.address);
  };

  return (
    <>
      <section>
        <div className={cx(bannerWrapper, 'flex items-center')}>
          <Container>
            <div className="banner-txt">
              <h1 className={cx(header, 'font-header')}>My Account</h1>
            </div>
          </Container>
        </div>
      </section>
      <section>
        <div className={cx(radial, radialBlog)} />
        <Container>
          {(!isSubscribed || updateTier)
            ? <TiersList back={isSubscribed ? switchToAccountPage : undefined} />
            : (
              <>
                <h2 className="mt-24 text-center">Active Subscription</h2>
                <h3 className={cx(cyan, 'text-center')}>
                  Tier {subscription?.tier}
                  <button type="button" className="pl-2 underline" onClick={switchToTiersPage}>
                    update
                  </button>
                </h3>
                <div className="grid grid-rows-3 grid-cols-1 lg:grid-cols-3 lg:grid-rows-1 gap-y-10 lg:gap-x-10 pb-20">
                  <div className={cx(tokenBox, 'text-center', 'lg:text-left')}>
                    <h4>Subscription</h4>
                    <p className="mb-0 font-semibold flex justify-between">
                      <span>Start date:</span>
                      <span>{(new Date(subscription?.subscriptionDetails.startDateMs ?? 0)).toLocaleString()}</span>
                    </p>
                    <p className="mb-0 font-semibold flex justify-between">
                      <span>End date:</span>
                      <span>{(new Date(subscription?.subscriptionDetails.endDateMs ?? 0)).toLocaleString()}</span>
                    </p>
                    <p className="mb-0 font-semibold flex justify-between">
                      <span>Deposit: {fromCereCents(subscription?.subscriptionDetails.balance ?? 0)} CERE</span>
                      <button type="submit" className={cx(cyan)} onClick={doRefund}>&nbsp; refund</button>
                    </p>
                  </div>
                  <div className={cx(tokenBox, 'text-center', 'lg:text-left')}>
                    <h4>Storage</h4>
                     <Progress value={storageUsageProgress} />
                    <p className="mb-0 font-semibold">
                      {memoryFormat(subscription?.metrics?.storageBytes ?? 0)} of{' '}
                      {memoryFormat(subscription?.limits?.storageBytes ?? 0)}
                    </p>
                  </div>
                  <div className={cx(tokenBox, 'text-center', 'lg:text-left')}>
                    <h4>Capacity Units</h4>
                    <h5 className="mt-4 mb-0">Limits</h5>
                    <p className="mb-0 font-semibold">
                      WCU: {subscription?.limits?.wcuPerMinute ?? 0} per minute
                    </p>
                    <p className="mb-0 font-semibold">
                      RCU: {subscription?.limits?.rcuPerMinute ?? 0} per minute
                    </p>
                    <h5 className={cx('mt-4 mb-0', cyan)}>Usage</h5>
                    <p className="mb-0 font-semibold">
                      WCU used: {subscription?.metrics?.wcuUsed ?? 0}
                    </p>
                    <p className="mb-0 font-semibold">
                      RCU used: {subscription?.metrics?.rcuUsed ?? 0}
                    </p>
                  </div>
                </div>
                <h2 className="mt-24 mb-8 text-center">Send data</h2>
                 <AccountSendForm />
              </>
            )}
        </Container>
      </section>
    </>
  );
}
