import { promisify, subscribe as subscribeApi } from './contract-api';
import { User } from '../../shared/provider';
import { Tier } from '../../types';
import { Subscriber } from './contract-api/types';
import { DdcClient } from '../ddc/ddc-client';

type SubscribeParams = {
  user: User;
  tier: Tier;
  subscriber: Subscriber;
  ddcClient: DdcClient;
};

export async function subscribe({ user, tier, subscriber, ddcClient }: SubscribeParams): Promise<boolean> {
  const result = await promisify(subscribeApi, subscriber, user, tier, ddcClient);
  return result ? result.isCompleted : false;
}
