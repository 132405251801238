type RequestParams = Record<string | number | symbol, unknown>;

type RequiredSome<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> & {
  [P in K]-?: T[P]
}

type URLSearchParamsWithEntries = { entries: () => Iterable<unknown> } & URLSearchParams;

type RequestProcessor<T extends string|Blob> = (r: Promise<Response>) => Promise<T>

type RequestOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: RequestParams;
  headers?: Record<string | number | symbol, unknown>;
};

type WithoutMethodRequestOptions = Omit<RequestOptions, 'method'>;
type WithParamsRequestOptions = Omit<RequiredSome<RequestOptions, 'params'>, 'method'>;

const REQUEST_TIMEOUT = 20000;

function encodeUrlParams(search: URLSearchParams, params: RequestParams) {
  Object.entries(params).forEach(([name, value]) => {
    if (Array.isArray(value)) {
      value.forEach((part) => search.append(name, part));
    } else if (typeof value === 'string') {
      search.set(name, value);
    } else {
      search.set(name, JSON.stringify(value));
    }
  });
}

function processRequestAsJson<T = unknown>(fetchRequest: Promise<Response>): Promise<T> {
  return fetchRequest
    .then((response) => response.text())
    .then((responseBody) => (responseBody === '' ? {} : JSON.parse(responseBody)));
}

export function processRequestAsText(fetchRequest: Promise<Response>): Promise<string> {
  return fetchRequest.then((response) => response.text());
}

export function processRequestAsBlob(fetchRequest: Promise<Response>): Promise<Blob> {
  return fetchRequest.then((response) => response.blob());
}
function processRequest<T extends string | Blob>(
  processor: RequestProcessor<T>,
  fetchRequest: Promise<Response>,
): ReturnType<typeof processor> {
  return processor(fetchRequest);
}
export function request<T extends string | Blob>(
  url: string,
  requestOptions: RequestOptions,
  processRequestFn: RequestProcessor<T>,
): ReturnType<typeof processRequestFn> {
  const controller = new AbortController();
  const method = ((requestOptions && requestOptions.method) || 'GET').toUpperCase();
  const { params = {}, headers = {}, ...otherRequestOptions } = requestOptions || {};
  const [pathname, search = ''] = url.split('?');
  const requestSearch = new URLSearchParams(search) as URLSearchParamsWithEntries;

  Object.entries(headers).forEach(([header, value]) => {
    if (header !== header.toLowerCase()) {
      delete headers[header];
      headers[header.toLowerCase()] = value;
    }
  });

  if (method === 'GET') {
    encodeUrlParams(requestSearch, params);
  }

  const fetchUrl = Array.from(requestSearch.entries()).length === 0
    ? pathname
    : `${pathname}?${requestSearch.toString()}`;

  setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
  const fetchRequest = fetch(fetchUrl, {
    headers: { 'content-type': 'application/json', ...headers },
    signal: controller.signal,
    body: method === 'GET' ? null : JSON.stringify(params),
    ...otherRequestOptions,
  }).then((response) => (
    response.ok
      ? response
      : response.text().then((text) => Promise.reject(Error(JSON.stringify({
        status: response.status,
        statusText: response.statusText,
        body: text,
      }))))
  ));

  return processRequest(processRequestFn, fetchRequest);
}

export function getJson(url: string, requestOptions?: WithoutMethodRequestOptions): Promise<unknown> {
  return request(url, { ...requestOptions, method: 'GET' }, processRequestAsJson);
}

export function getText(url: string, requestOptions?: WithoutMethodRequestOptions): Promise<string> {
  return request(url, { ...requestOptions, method: 'GET' }, processRequestAsText);
}

export function postJson(url: string, requestOptions: WithoutMethodRequestOptions): Promise<unknown> {
  return request(url, { ...requestOptions, method: 'POST' }, processRequestAsJson);
}

export function postText(url: string, requestOptions: WithoutMethodRequestOptions): Promise<string> {
  return request(url, { ...requestOptions, method: 'POST' }, processRequestAsText);
}

export function postBlob(url: string, requestOptions: WithoutMethodRequestOptions): Promise<Blob> {
  return request(url, { ...requestOptions, method: 'POST' }, processRequestAsBlob);
}

export function patchJson(url: string, requestOptions: WithParamsRequestOptions): Promise<unknown> {
  return request(url, { ...requestOptions, method: 'PATCH' }, processRequestAsJson);
}

export function deleteJson(url: string, requestOptions?: WithoutMethodRequestOptions): Promise<unknown> {
  return request(url, { ...requestOptions, method: 'DELETE' }, processRequestAsJson);
}
