import { ReactElement, useCallback, useContext, useState } from 'react';
import { css, cx } from '@linaria/core';

import { bannerWrapper, header } from '../login/login.styles';
import { Container } from '../../layout/container';
import { radial, radialBlog } from '../../app.styles';
import { row, tokenBox } from '../../shared/tiers-list/styles';
import { AccountField } from '../account/account-field';
import { btn } from '../home/buttons.styles';
import { AppContext } from '../../shared/provider';
import { Token } from '../../types';
import { NftAssets } from './nft-assets';
import { Loader } from '../../shared/loader';
import { NftTokenCard } from './nft-token-card';

const h3Header = css`
  font-size: 2.4rem;
  line-height: 3.6rem;
`;

const table = css`
  grid-template-columns: 1fr;
  align-items: start;
`;

const emptyError = '';
const emptyTokens: Token[] | null = null;

export function AssetViewer(): ReactElement {
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [loadingError, setLoadingError] = useState<string>(emptyError);
  const [isLoading, updateLoading] = useState<boolean>(false);
  const [activeNft, setActiveNft] = useState<Token | null>(null);
  const [activeAssetCids, setActiveAssetCids] = useState<string[]>([]);
  const [tokens, setTokens] = useState<Token[] | null>(emptyTokens);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { davinciClient } = useContext(AppContext);
  const loadData = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        setTokens(emptyTokens);
        setLoadingError(emptyError);
        updateLoading(true);
        const formData = new FormData(e.currentTarget);
        const userKey: string = formData.get('userPubKey') as string;

        const resultTokens = await davinciClient.getNfts(userKey);
        setSubmitted(true);
        setUserAddress(userKey);
        setTokens(resultTokens);
      } catch (error) {
        setLoadingError(error.message);
        // eslint-disable-next-line no-console
        console.error(error);
      }
      updateLoading(false);
    },
    [davinciClient],
  );

  const showAssetsForNft = (token: Token, cids: string[]) => {
    setActiveNft(token);
    setActiveAssetCids(Array.from(new Set(cids).values()));
  };

  const back = () => {
    setActiveAssetCids([]);
    setActiveNft(null);
  };

  if (activeNft && userAddress) {
    return <NftAssets cids={activeAssetCids} nft={activeNft} onBack={back} />;
  }

  return (
    <>
      <section>
        <div className={cx(bannerWrapper, 'flex items-center')}>
          <Container>
            <div className="banner-txt">
              <h1 className={cx(header, 'font-header')}>Asset Viewer</h1>
            </div>
          </Container>
        </div>
      </section>
      <section>
        <div className={cx(radial, radialBlog)} />
        <Container>
          <div className={cx(table, 'grid gap-x-10 gap-y-6 py-20')}>
            <div className={tokenBox}>
              <h3 className={h3Header}>Load NFT-s</h3>
              <form onSubmit={loadData} className="pb-8">
                <AccountField required name="userPubKey" label="Account Public Key" value="" />
                <button
                  className={cx(btn, 'relative -left-2 mt-4 flex justify-center items-center')}
                  type="submit"
                  disabled={isLoading}
                >
                  Load NFT-s {isLoading && <Loader />}
                </button>
              </form>
            </div>
            <div className="text-left">
              {loadingError && <h3 className="text-3xl mb-0 mt-12 text-red-300">{loadingError}</h3>}
            </div>
            <div className={cx(row, 'flex flex-row flex-wrap')}>
              {submitted && tokens && tokens.length === 0 ? (
                <div className={cx('my-4 pl-2')}>
                  <h3 className="text-3xl mb-0 mt-12 text-red-300">NFT-s not found for the selected user</h3>
                </div>
              ) : null}
              {tokens && tokens.map((token) => (
                <NftTokenCard key={token.nftId} token={token} showAssetsForNft={showAssetsForNft} />
              ))}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
