import Crc32 from 'crc-32';

import { ApplicationPartition, CidResponse, DdcRecordMetadata, isApplicationTopology, isCidResponse } from './types';
import { getJson, postJson } from '../request';
import { User } from '../../shared/provider';
import { sortBy } from '../sort-by';

type PieceKeys = {
  appPubKey: string;
  userPubKey: string;
};

type Piece = {
  id: string | null;
  timestamp: string;
  data: string;
  signature: string;
} & PieceKeys;

export class DdcClient {
  constructor(private apiRoot: string) {}

  getMetrics(): Promise<string> {
    return this.fetch('/api/rest/metrics').then((r) => r.text());
  }

  async loadData({ userPubKey, appPubKey }: PieceKeys): Promise<unknown[]> {
    const partitions = sortBy(
      await this.getAvailablePartitions({ userPubKey, appPubKey }),
      (partition) => partition.createdAt,
    );
    const urls = partitions.map(({ master, partitionId }) => {
      const url = new URL(master.nodeHttpAddress);
      url.pathname = '/api/rest/pieces';
      url.searchParams.set('userPubKey', userPubKey);
      url.searchParams.set('appPubKey', appPubKey);
      url.searchParams.set('partitionId', partitionId);
      return url.href;
    });

    return Promise.all(urls.map(
      (url) => fetch(url)
        .then((response) => response.body)
        .then((rs) => {
          const reader = rs?.getReader();
          return reader && new ReadableStream({
            async start(controller) {
              let condition = true;
              while (condition) {
                // eslint-disable-next-line no-await-in-loop
                const { done, value } = await reader.read();
                if (done) {
                  break;
                }
                condition = !done;
                controller.enqueue(value);
              }
              controller.close();
              reader?.releaseLock();
            },
          });
        })
        .then((rs) => new Response(rs))
        .then((response) => response.text())
        .then((text) => text.split('\n').map((line) => line.trim()).filter(Boolean))
        .then((lines) => JSON.parse(`[${lines.join(',')}]`)),
    ));
  }

  async saveSubscription(user: User, signature: string): Promise<void> {
    const url = new URL(this.apiRoot);
    url.pathname = '/api/rest/apps';

    await postJson(url.href, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      params: {
        appPubKey: user.principal,
        signature,
      },
    });
  }

  async save(piece: Piece, metadata?: DdcRecordMetadata): Promise<CidResponse> {
    const ddcNodeUrl = new URL(await this.getFirstActivePartitionUrl(piece));
    ddcNodeUrl.pathname = '/api/rest/pieces';
    return postJson(ddcNodeUrl.href, {
      params: metadata? { ...piece, metadata } : piece,
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
    })
      .then((response) => {
        if (isCidResponse(response)) {
          return response;
        }
        throw Error(`Unexpected response from ddc node: ${JSON.stringify(response)}`);
      });
  }

  isAppRegistered(appPubKey: string): Promise<boolean> {
    return getJson(this.getApiUrl(`/api/rest/apps/${appPubKey}`))
      .then(() => true)
      .catch((e) => {
        const errorDetails = JSON.parse(e.message);
        if (errorDetails.status === 404) {
          return false;
        }
        throw e;
      });
  }

  getFirstActivePartitionUrl({ userPubKey, appPubKey }: PieceKeys): Promise<string> {
    return this.getActivePartitions({ userPubKey, appPubKey })
      .then((partitions) => {
        const partition = partitions[0];
        if (partition) {
          return partition;
        }
        throw Error(`No partition found for ${appPubKey}, founded partitions: ${JSON.stringify(partitions)}`);
      })
      .then((partition) => partition.master.nodeHttpAddress);
  }

  getAvailablePartitions({ userPubKey, appPubKey }: PieceKeys): Promise<ApplicationPartition[]> {
    const ringToken = Array.from(new Uint32Array([Crc32.str(userPubKey)]))
      .shift() ?? 0;
    return this.fetch(`/api/rest/apps/${appPubKey}/topology`)
      .then((r) => r.json())
      .then((topology) => {
        if (isApplicationTopology(topology)) {
          return topology;
        }
        throw Error(`Wrong topology response ${JSON.stringify(topology)}`);
      })
      .then((topology) => topology.partitions.filter((p) => p.sectorStart <= ringToken && ringToken <= p.sectorEnd));
  }

  getActivePartitions({ userPubKey, appPubKey }: PieceKeys): Promise<ApplicationPartition[]> {
    return this.getAvailablePartitions({ userPubKey, appPubKey })
      .then((partitions) => partitions.filter((partition) => partition.active));
  }

  private getApiUrl(path: string): string {
    const url = new URL(this.apiRoot);
    url.pathname = path;
    return url.href;
  }

  // eslint-disable-next-line no-undef
  private fetch(url: string, options?: RequestInit): Promise<Response> {
    const link = new URL(this.apiRoot);
    link.pathname = url;
    return fetch(link.href, options);
  }
}
