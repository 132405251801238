import { ContractQueryResult } from './types';
import { getPolkadotContract } from '../tools/api-tools';

export async function* tierIdOf(userAddress: string): ContractQueryResult {
  yield { log: '- Connecting to the contract...' };
  const contract = await getPolkadotContract();
  yield { log: '+ Connected' };
  yield { log: '- Executing tier_id_of...' };
  const response = await contract.query.tierIdOf(
    userAddress,
    {
      storageDepositLimit: null,
      gasLimit: -1,
    },
    userAddress,
  );
  yield {
    result: response,
    log: '+ Executing tier_id_of',
  };
}
