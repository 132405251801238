import { ResultWithLog, Subscriber } from './types';

const defaultSubscriber = (log: string|null, err?: Error) => {
  if (process.env.NODE_ENV === 'development') {
    if (log) {
      // eslint-disable-next-line no-console
      console.log(log);
    } else if (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
};

export async function promisify<ArgsType extends unknown[], ReturnType>(
  generator: (...genArgs: ArgsType) => AsyncGenerator<ResultWithLog<ReturnType>>,
  subscriber: Subscriber = defaultSubscriber,
  ...args: ArgsType
): Promise<ReturnType | undefined> {
  const gen = generator(...args);
  let step;
  try {
    // eslint-disable-next-line no-restricted-syntax
    for await (step of gen) {
      if (step.error) {
        throw step.error;
      } else {
        // eslint-disable-next-line no-unused-expressions
        step.log && subscriber(step.log);
      }
    }
  } catch (e) {
    subscriber(null, e);
    throw e;
  }

  return step?.result;
}
