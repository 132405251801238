/* eslint-disable no-restricted-syntax */
import Web3 from 'web3';

import { getJson } from '../request';
import { AssetDdc, isAssetDdc, isToken, Token } from '../../types';
import { getSigner } from '../get-signer';

const web3 = new Web3(Web3.givenProvider);

type GetContentProps = {
  minter: string;
  cid: string;
  ddcAddress: string;
};

export class DavinciClient {
  constructor(private readonly freeportApi: string, private readonly ddcProxyApi: string) {
  }

  async getNfts(account: string): Promise<Token[]> {
    const urlMinted = new URL(this.freeportApi);
    urlMinted.pathname = `/wallet/${account}/nfts/minted`;

    const urlOwned = new URL(this.freeportApi);
    urlOwned.pathname = `/wallet/${account}/nfts/owned`;

    const minted = await DavinciClient.getTokensByUrl(urlMinted.href);
    const owned = await DavinciClient.getTokensByUrl(urlOwned.href);

    return Array.from(new Map([...minted, ...owned].map((token) => [token.nftId, token])).values());
  }

  getNftCids = async (nftId: string): Promise<string[]> => {
    const url = new URL(this.freeportApi);
    url.pathname = `/nft/${nftId}/cids`;
    const response = await getJson(url.href, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });
    return Array.isArray(response) ? response.map(String) : [];
  };

  getCidsMetadata = async (minter: string, cids: string[]): Promise<{ [key: string]: AssetDdc }> => {
    const result: Record<string, AssetDdc> = {};
    const requests = await Promise.allSettled(cids.map(async (cid): Promise<[string, unknown]> => {
      const url = new URL(this.ddcProxyApi);
      url.pathname = `/assets/v1/${minter}/${cid}`;
      const response = await getJson(url.href, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return [cid, response];
    }));
    for (const request of requests) {
      if (request.status === 'fulfilled') {
        const [cid, value] = request.value;
        if (isAssetDdc(value)) {
          result[cid] = value;
        }
      }
    }
    return result;
  };

  async getCidContent({minter, cid, ddcAddress}: GetContentProps): Promise<Blob> {
    const url = new URL(this.ddcProxyApi);
    url.pathname = `/assets/v1/${minter}/${cid}/content`;

    const signer = await getSigner();
    const parts = [
      `Confirm identity:`,
      `Minter: ${minter}`,
      `CID: ${cid}`,
      `Address: ${ddcAddress}`,
    ];
    const signature = await signer.signMessage(parts.join('\n'));
    return fetch(url.href, {
      headers: {
        'X-DDC-Signature': signature,
        'X-DDC-Address': ddcAddress,
      },
    }).then(async (response) => {
      if (!response.ok) {
        const reason = await response.text();
        throw new Error(`Error on loading content ${response.status}, ${reason}`)
      }
      return response.blob();
    });
  }

  static async getTokensByUrl(url: string): Promise<Token[]> {
    const result = (await getJson(url, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    })) as unknown[];

    return result.filter(isToken);
  }

  async getContent(address: string, assetId: number): Promise<Blob> {
    const url = new URL(this.ddcProxyApi);
    url.pathname = `/assets/${assetId}`;
    const signature = await DavinciClient.getContentSignature(address);

    return fetch(url.href, {
      method: 'GET',
      headers: {
        'X-DDC-Address': address,
        'X-DDC-Signature': signature,
      },
    }).then((response) => response.blob());
  }

  static async getContentSignature(address: string): Promise<string> {
    const parts = ['Confirm identity:', `Address: ${address}`];
    const account = await DavinciClient.getAccountFromMetamask();

    return DavinciClient.sign(account, parts.join('\n'));
  }

  static async getAccountFromMetamask(): Promise<string> {
    const accounts = await web3.eth.requestAccounts();

    return accounts[0];
  }

  static sign(account: string, nonce: string): Promise<string> {
    return web3.eth.personal.sign(`${nonce}`, account, '');
  }
}
