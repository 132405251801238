import { ContractQueryResult } from './types';
import { getPolkadotContract } from '../tools/api-tools';

export async function* metricsSinceSubscription(userAddress: string): ContractQueryResult {
  yield { log: '- Connecting to the contract...' };
  const contract = await getPolkadotContract();
  yield { log: '+ Connected' };
  yield { log: '- Executing metrics_since_subscription...' };
  const response = await contract.query.metricsSinceSubscription(
    userAddress,
    {
      storageDepositLimit: null,
      gasLimit: -1,
    },
    userAddress,
  );
  yield {
    result: response,
    log: '+ Executing metrics_since_subscription completed',
  };
}
