import { ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AppContext } from './app-context';
import { AppContextType, isUserSession, Subscription, User, UserSession } from './types';
import { Loader } from './loader';
import { Tier } from '../../types';
import { getAllTiers, getSubscription } from '../../lib/polkadot-api';
import { delay } from '../../lib/delay';
import { getPrincipal } from '../../lib/get-principial';
import { getPolkadotContract } from '../../lib/polkadot-api/tools/api-tools';

export type AppProviderType = {
  children: ReactNode;
};

const USER_KEY = 'user';

enum AppStateType {
  initial = 1,
  ready = 2,
  updating = 4,
  failed = 3,
}

export function AppProvider({ children }: AppProviderType): ReactElement {
  const [user, setUser] = useState<User | null>(null);
  const { ddcClient, davinciClient, eventService } = useContext(AppContext);
  const [token] = useState<string>('CERE');
  const [error, setAppError] = useState<string | undefined>();
  const [appState, setAppState] = useState<AppStateType>(AppStateType.initial);
  const [tiers, setTiers] = useState<Tier[]>([]);
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const updateSubscription = useCallback(async (userAddress?: string): Promise<void> => {
    if (!userAddress) {
      setSubscription(null);
    } else {
      await delay(2000);
      setSubscription(await getSubscription(userAddress, ddcClient));
    }
  }, [ddcClient]);

  const loadTiers = useCallback(async () => {
    setTiers(await getAllTiers());
  }, []);

  const loadUser = useCallback((): UserSession | null => {
    const userData = window.localStorage.getItem(USER_KEY) ?? '';
    try {
      const session = JSON.parse(userData);
      return isUserSession(session) ? session : null;
    } catch (e) {
      return null;
    }
  }, []);

  const saveUser = useCallback((userData: Omit<User, 'principal'> | null) => {
    if (userData == null) {
      setUser(null);
    } else {
      setUser({ ...userData, principal: getPrincipal(userData.address) });
    }
  }, []);

  const login = useCallback(
    (userData) => {
      window.localStorage.setItem(USER_KEY, JSON.stringify(userData));
      saveUser(userData);
    },
    [saveUser],
  );

  const logout = useCallback(() => {
    saveUser(null);
    window.localStorage.removeItem(USER_KEY);
  }, [saveUser]);

  const storageEventHandler = useCallback(
    (event) => {
      if (event.key === USER_KEY) {
        const userData = event.newValue;
        try {
          saveUser(JSON.parse(userData));
        } catch (e) {
          saveUser(null);
        }
      }
    },
    [saveUser],
  );

  useEffect(() => {
    window.addEventListener('storage', storageEventHandler);
    return () => window.removeEventListener('storage', storageEventHandler);
  }, [storageEventHandler]);

  useEffect(() => {
    setAppState((currentState) => {
      if (currentState === AppStateType.initial || currentState === AppStateType.updating) {
        return currentState;
      }
      return AppStateType.updating;
    });
    updateSubscription(user?.address)
      .then(() => setAppState((currentState) => (currentState === AppStateType.updating
        ? AppStateType.ready
        : currentState)))
      .catch(() => {
        // setAppError(err.message);
        // setAppState(AppStateType.failed);
        setAppState(AppStateType.ready)
      });
  }, [updateSubscription, user?.address]);

  useEffect(() => {
    const currentUser = loadUser();
    let completed = false;
    setUser(
      currentUser && {
        ...currentUser,
        principal: getPrincipal(currentUser?.address),
      },
    );
    delay(120 * 1000).then(() => {
      if (!completed) {
        setAppError("After 2 minutes of processing, the necessary data isn't loaded. Please, try to reload the page.");
      }
    });
    getPolkadotContract().then(() => Promise.all([updateSubscription(currentUser?.address), loadTiers()])
      .then(() => setAppState(AppStateType.ready))
      .catch(() => {
        // setAppError(err.message)
        setAppState(AppStateType.ready)
      })
      .finally(() => {
        completed = true;
      }));
  }, [loadTiers, loadUser, updateSubscription]);

  const fullUser = useMemo(() => (user === null ? null : { ...user, subscription }), [subscription, user]);

  const context = useMemo(
    () => ({
      login,
      logout,
      user: fullUser,
      updateSubscription,
      tiers,
      token,
      ddcClient,
      davinciClient,
      eventService
    } as AppContextType),
    [login, logout, fullUser, updateSubscription, tiers, token, ddcClient, davinciClient, eventService],
  );

  return (
    <AppContext.Provider value={context}>
      <Loader error={error} completed={appState === AppStateType.ready}>
        {children}
      </Loader>
    </AppContext.Provider>
  );
}
