type Event = {data: unknown, previousCid?: string}

export class CereEtlServiceClient {

  constructor(private readonly cereEtlApi: string) {}

  async fetchEvents (appId: string, userId: string, limit = 50) {
    const url = new URL(this.cereEtlApi);
    url.pathname = `/events/${appId}-${userId}`;
    url.searchParams.set('limit', Math.ceil(limit).toString());
    const response = await fetch(url.href);
    const events = await response.json();
    return events as Array<Event>
  }

  fetchLastCid(appId: string, userPublicKey: string) {
    const url = new URL(this.cereEtlApi);
    url.pathname = `/cid/${appId}-${userPublicKey}`;
    return fetch(url.href).then((r) => r.text());
  }

  fetchRootPieceUri(appId: string, userPublicKey: string) {
    const url = new URL(this.cereEtlApi);
    url.pathname = `/uri/${appId}-${userPublicKey}`;
    return fetch(url.href).then((r) => r.text());
  }
}


