import { css } from '@linaria/core';

export const preloader = css`
  --width: 50%;
  --blue: #061649;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  transition: width 1s ease, height 1s ease;
  z-index: 100;

  &.open-page {
    --width: 0;

    animation: fadeOut 500ms 500ms linear 1 both;

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  &:after,
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    width: var(--width);
    height: 100vh;
    background: var(--blue);
    transition: all 2s;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    left: auto;
  }
`;

export const counter = css`
  --size: 10rem;
  font-size: 3rem;
  width: 80vw;
  height: var(--size);
  border-radius: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--cyan);
  z-index: 120;
`;

export const fadeInOut = css`
  animation: fade 1s 2s linear infinite alternate;

  @keyframes fade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;
