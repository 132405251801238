import { stringToHex } from '@polkadot/util';
import { ISubmittableResult } from '@polkadot/types/types/extrinsic';
import { web3FromSource } from '@polkadot/extension-dapp';

import { ContractTxResult } from './types';
import { getAccount } from '../tools/get-account';
import { getPolkadotContract, toCereCents } from '../tools/api-tools';
import { Tier } from '../../../types';
import { DdcClient } from '../../ddc/ddc-client';
import { User } from '../../../shared/provider';

export async function* subscribe(user: User, tier: Tier, ddcClient: DdcClient): ContractTxResult {
  yield { log: '- Looking for account...' };
  const account = await getAccount(user.address);
  yield { log: '+ Account found' };
  yield { log: '- Connecting to the contract...' };
  const contract = await getPolkadotContract();
  yield { log: '+ Connected' };

  yield { log: '- Executing subscribe...' };
  const value = toCereCents(tier.fee);
  const { signer } = await web3FromSource(account.meta.source);

  const { gasConsumed } = await contract.query.subscribe(user.address, {
    storageDepositLimit: null,
    gasLimit: -1,
    value,
  }, tier.num);

  const txResult: ISubmittableResult = await new Promise((resolve, reject) => {
    contract.tx
      .subscribe({ value, storageDepositLimit: null, gasLimit: gasConsumed.toString() }, tier.num)
      .signAndSend(user.address, { signer }, (result) => {
        if (result.isCompleted) {
          resolve((result as unknown) as ISubmittableResult);
        } else if (result.isError) {
          reject(result);
        }
      });
  });
  yield ({ log: '+ Executing subscribe completed' });

  const isAppRegistered = await ddcClient.isAppRegistered(user.principal);
  if (isAppRegistered) {
    return;
  }

  yield ({ log: '- Creating account in DDC...' });
  const userApprove = await signer.signRaw?.({
    address: user.address,
    data: stringToHex(user.principal),
    type: 'bytes',
  });
  await ddcClient.saveSubscription(user, userApprove?.signature ?? '')
    .catch((err) => {
      try {
        const errDetails = JSON.parse(err.message);
        const body = JSON.parse(errDetails?.body);
        return Promise.reject(body.message ? Error(body.message) : err);
      } catch (e) {
        throw err;
      }
    });
  yield ({ log: '+ Registered in DDC' });
  yield ({ log: '+ Done', result: txResult });
}
