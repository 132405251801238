import { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { cx } from '@linaria/core';
import { Redirect } from 'react-router-dom';
import { web3Accounts, web3Enable, web3FromSource } from '@polkadot/extension-dapp';
import { decodeAddress } from '@polkadot/util-crypto';
import { stringToHex, u8aToHex } from '@polkadot/util';

import { Container } from '../../layout/container';
import { radial, radialBlog } from '../../app.styles';
import { bannerWrapper, header, header3, select, tokenForm } from './login.styles';
import { btn } from '../home/buttons.styles';
import { AppContext } from '../../shared/provider';

enum ExtensionState {
  Loading = 'Loading',
  PluginError = 'PluginError',
  Success = 'Success'
}

type Accounts = {
  name: string;
  value: string;
};

export function Login(): ReactElement {
  const [state, setState] = useState<ExtensionState>(ExtensionState.Loading);
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const { login, user } = useContext(AppContext);
  const keyToAddress = useRef(new Map());

  const submit = useCallback(async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    const formData = new FormData(form);
    const key = formData.get('pub-key');
    const arr = new Int32Array(1);
    window.crypto.getRandomValues(arr);
    const nonce = Array.from(arr)[0].toString();
    const account = keyToAddress.current.get(key);
    const injector = await web3FromSource(account.meta.source);
    await injector?.signer?.signRaw?.({
      address: account.address,
      data: stringToHex(nonce),
      type: 'bytes',
    });
    login({
      name: account.meta.name,
      address: account.address,
    });
  }, [login]);

  useEffect(() => {
    web3Enable?.('DDC').then((extensions) => {
      if (extensions.length === 0) {
        setState(ExtensionState.PluginError);
      } else {
        setState(ExtensionState.Success);
      }
    });
  }, []);

  useEffect(() => {
    web3Accounts().then((extAccounts) => {
      setAccounts(extAccounts.map((account) => {
        const key = u8aToHex(decodeAddress(account.address));
        keyToAddress.current.set(key, account);
        return { name: `${account.meta.name} (${account.address})`, value: key };
      }));
    });
  }, []);

  if (user) {
    return <Redirect to="/account" />;
  }

  return (
    <>
      <section>
        <div className={cx(bannerWrapper, 'flex items-center')}>
          <Container>
            <div className="banner-txt">
              <h1 className={cx(header, 'font-header')}>Sign Up</h1>
            </div>
          </Container>
        </div>
      </section>
      <section className="section" id="loading">
        <div className={cx(radial, radialBlog)} />
        <Container>
          <div className={cx(tokenForm, 'mt-48')}>
            {
              state === ExtensionState.Loading && (
                <h3 className={cx(header3, 'font-header')}>
                  <i className="icon-sing-in-1" />
                  {' '}
                  Loading Polkadot Wallet extension...
                </h3>
              )
            }
            {
              state === ExtensionState.PluginError && (
                <h3 className={cx(header3, 'font-header')}>
                  <i className="icon-sing-in-1" />
                  {' '}
                  Looks like you don&#39;t have
                  {' '}
                  <a href="https://polkadot.js.org/extension/" target="_blank" rel="noopener noreferrer">
                    Polkadot extension
                  </a>
                  {' '}
                  in your browser or you didn&#39;t allow access for that website.
                </h3>
              )
            }
            {
              state === ExtensionState.Success && (
                <form onSubmit={submit}>
                  <h3 className={cx(header3, 'font-header')}>
                    <i className="icon-sing-in-1" />
                    {' '}
                    Select account to login
                  </h3>
                  {
                    accounts.length > 0 && (
                      <select className={cx(select, 'w-full mb-12')} name="pub-key">
                        {
                          accounts.map(({ name, value }) => (
                            <option key={name} value={value}>{name}</option>
                          ))
                        }
                      </select>
                    )
                  }
                  <button className={btn} disabled={accounts.length === 0} type="submit">Log in</button>
                </form>
              )
            }
          </div>
        </Container>
      </section>
    </>
  );
}
