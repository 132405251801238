import { ContractPromise } from '@polkadot/api-contract';
import { ApiPromise } from '@polkadot/api';
import { polkadotTools } from './polkadot-tools';

import { BlockchainConfig } from '../../../types';

export function getBlockchainConfig(): BlockchainConfig {
  const config = { 'ws-url': '', decimals: 0, contract: { address: '' } } as BlockchainConfig;
  const DEFAULT_DECIMALS = 10;
  if (process.env.BLOCKCHAIN_WS_URL) {
    config['ws-url'] = process.env.BLOCKCHAIN_WS_URL;
  }
  if (process.env.BLOCKCHAIN_DECIMALS) {
    config.decimals = Number(process.env.BLOCKCHAIN_DECIMALS);
  } else {
    config.decimals = DEFAULT_DECIMALS;
  }
  if (process.env.BLOCKCHAIN_CONTRACT_ADDRESS) {
    config.contract.address = process.env.BLOCKCHAIN_CONTRACT_ADDRESS;
  }
  return config;
}

export function fromCereCents(cents: number): number {
  const { decimals } = getBlockchainConfig();

  return cents / 10 ** decimals;
}

export function toCereCents(cents: number): number {
  const { decimals } = getBlockchainConfig();

  return cents * 10 ** decimals;
}

export function getPolkadotApi({ forceLoadApi = false } = {}): Promise<ApiPromise> {
  return polkadotTools.getPolkadotApi({ forceLoadApi });
}

export async function getPolkadotContract({
  forceLoadApi = false,
  forceLoadContract = false,
} = {}): Promise<ContractPromise> {
  return polkadotTools.getPolkadotContract({ forceLoadContract, forceLoadApi });
}
