import { css, cx } from '@linaria/core';
import { ReactElement } from 'react';

const loader = css`
  display: inline-block;
  width: 40px;
  height: 40px;
  &:after {
    content: ' ';
    display: block;
    width: 32px;
    height: 32px;
    margin: 5px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export function Loader(): ReactElement {
  return <div className={cx(loader)} />;
}
