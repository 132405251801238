import { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import JSONPretty from 'react-json-pretty';
import { css, cx } from '@linaria/core';

import { Bounce, Zoom } from 'react-awesome-reveal';
import { bannerWrapper, header } from '../login/login.styles';
import { Container } from '../../layout/container';
import { radial, radialBlog } from '../../app.styles';
import { tokenBox } from '../../shared/tiers-list/styles';
import { AccountField } from '../account/account-field';
import { btn, btnSmall } from '../home/buttons.styles';
import { AppContext } from '../../shared/provider';
import { useSafeState } from '../../lib/hooks';
import { ALL_PATHS, CryptoSecretKey } from '../../lib/crypto-v1';
import { DdcRecord, toDdcRecord } from '../../lib/ddc/types';
import { Loader } from '../../shared/loader';
import { appIdToTop, parseJson, isString } from '../../lib/crypto-v1/utils';
import { mockData } from './mock-data';
import { metadata } from '@polkadot/types/interfaces/essentials';

const h3Header = css`
  font-size: 2.4rem;
  line-height: 3.6rem;
`;

const table = css`
  grid-template-columns: 1fr;
  align-items: start;
`;

const recordChooser = css`
  grid-column: span 2;
`;

const jsonPretty = css`
  overflow: auto;
  word-wrap: break-word; 
`;

const contentItem = css`
  max-height: 300px;
  overflow: auto;
  word-wrap: break-word; 
`;

const hidden = css`
  display: none!important;
`;

const defaultRecord = null;

interface EncryptionKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (key: string) => void;
}

const EncryptionKeyModal: React.FC<EncryptionKeyModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [key, setKey] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (key.trim()) {
      onSubmit(key);
      setKey('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className={cx(tokenBox, 'w-[48rem] relative')}>
        <button onClick={onClose} className="absolute top-4 right-4 text-white text-2xl">&times;</button>
        <h2 className={h3Header}>Enter Encryption Master Key</h2>
        <form onSubmit={handleSubmit}>
          <input
            id="encryption-key"
            type="password"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            className="border p-4 mb-8 w-full text-3xl text-white bg-transparent [&::-ms-reveal]:filter [&::-ms-reveal]:invert [&::-ms-reveal]:w-8 [&::-ms-reveal]:h-8"
            style={{ fontSize: '2rem' }}
            placeholder="Enter encryption master key"
          />
          <div className="flex justify-end">
            <button type="submit" className={cx(btn, btnSmall, 'text-xl')} disabled={!key.trim()}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

interface ScopeDecryptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (scopeKey: string) => void;
  error: string;
}

const ScopeDecryptionModal: React.FC<ScopeDecryptionModalProps> = ({ isOpen, onClose, onSubmit, error }) => {
  const [scopeKey, setScopeKey] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (scopeKey.trim()) {
      onSubmit(scopeKey);
      setScopeKey('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className={cx(tokenBox, 'w-[48rem] relative')}>
        <button onClick={onClose} className="absolute top-4 right-4 text-white text-2xl">&times;</button>
        <h2 className={h3Header}>Enter Scope Encryption Key</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={scopeKey}
            onChange={(e) => setScopeKey(e.target.value)}
            className="border p-4 mb-4 w-full text-3xl text-white bg-transparent"
            placeholder="Enter scope encryption key"
          />
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <div className="flex justify-end">
            <button type="submit" className={cx(btn, btnSmall, 'text-xl')} disabled={!scopeKey.trim()}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export function DataViewer(): ReactElement {
  const { user, eventService } = useContext(AppContext);
  const [rawData, setRawData] = useSafeState('');
  const [paths, setPaths] = useSafeState(ALL_PATHS);
  const [userPubKey, setUserPubKey] = useSafeState('');
  const [appPubKey, setAppPubKey] = useSafeState('');
  const [foundRecords, setFoundRecords] = useSafeState<DdcRecord[] | null>(defaultRecord);
  const [loadError, setLoadError] = useSafeState('');
  const [decryptError, setDecryptError] = useSafeState('');
  const [decryptedData, setDecryptedData] = useSafeState('');
  const [submitting, setSubmitting] = useSafeState(false);
  const [connectionError, setConnectionError] = useSafeState('');
  const decryptFormRef = useRef<HTMLDivElement>(null);
  const [isEncryptMode, setIsEncryptMode] = useSafeState(true);
  const [encryptedItems, setEncryptedItems] = useState<Record<string, boolean>>({});
  const [encryptionMasterKey, setEncryptionMasterKey] = useState('');
  const [showKeyModal, setShowKeyModal] = useState(false);
  const [showScopeModal, setShowScopeModal] = useState(false);
  const [scopeDecryptError, setScopeDecryptError] = useState('');

  const loadData = useCallback(
    (e) => {
      e.preventDefault();
      setLoadError('');
      setDecryptedData('');
      setSubmitting(true);
      setFoundRecords(defaultRecord);
      setConnectionError('');

      // Simulate an asynchronous operation
      setTimeout(() => {
        const recordsWithUniqueIds = mockData.map((record, index) => ({
          ...record,
          id: generateUniqueId(index),
        }));
        setFoundRecords(recordsWithUniqueIds);

        // Initialize all items as encrypted by default
        const initialEncryptedState = recordsWithUniqueIds.reduce((acc, record) => {
          acc[record.id] = true;
          return acc;
        }, {} as Record<string, boolean>);
        setEncryptedItems(initialEncryptedState);

        setSubmitting(false);
      }, 1000); // Delay of 1 second to simulate loading
    },
    [setDecryptedData, setFoundRecords, setLoadError, setSubmitting, setConnectionError],
  );

  useEffect(() => {
    if (user?.principal) {
      setAppPubKey(user?.principal);
    }
  }, [setAppPubKey, user?.principal]);

  const updateRawText = useCallback(
    (text) => {
      setDecryptedData('');
      setRawData(text);
    },
    [setDecryptedData, setRawData],
  );

  const onUserPubKeyChange = useCallback((value) => {
    setUserPubKey(value);
  }, [setUserPubKey]);

  const onAppPubKeyChange = useCallback((value) => {
    setAppPubKey(value);
  }, [setAppPubKey]);

  const decrypt = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setDecryptError('');
      const formData = new FormData(e.currentTarget as HTMLFormElement);
      const jsonPaths = (formData.get('paths') as string).split(',').map((part) => part.trim());
      const masterKey = CryptoSecretKey.fromString(formData.get('encKey') as string);
      try {
        const decrypted = masterKey.decryptWithScopes(rawData, jsonPaths);
        setDecryptedData(decrypted);
      } catch (err) {
        console.error('Decryption error:', err);
        setDecryptError("Can't decrypt data using this key. Please check your encryption key and JSON paths.");
        setTimeout(() => setDecryptError(''), 5000);
      }
    },
    [rawData, setDecryptedData, setDecryptError],
  );

  const handleEncryptDecrypt = useCallback((id: string) => {
    setEncryptedItems((prev) => ({
      ...prev,
      [id]: !prev[id]
    }));
  }, []);

  const isJSON = (JSONString: string) => {
    let isJson = true;
    try {
      JSON.parse(JSONString);
    } catch (e) {
      isJson = false;
    }

    return isJson;
  };

  const accountFormValid = useMemo(() => !!userPubKey && !!appPubKey, [userPubKey, appPubKey]);

  const toggleAllEncryption = useCallback(() => {
    if (!encryptionMasterKey) {
      setShowKeyModal(true);
    } else {
      setIsEncryptMode((prev) => !prev);
      setEncryptedItems((prev) => {
        const newEncryptedItems = { ...prev };
        Object.keys(newEncryptedItems).forEach((id) => {
          newEncryptedItems[id] = !isEncryptMode;
        });
        return newEncryptedItems;
      });
      // Reset scoped data when toggling encryption
      setFoundRecords((prevRecords) =>
        prevRecords?.map((record) => ({ ...record, isScoped: false }))
      );
    }
  }, [isEncryptMode, encryptionMasterKey]);

  const handleKeySubmit = (key: string) => {
    setEncryptionMasterKey(key);
    setShowKeyModal(false);
    // Any other logic you want to perform with the key
  };

  const handleScopeDecrypt = (scopeKey: string) => {
    if (scopeKey === 'lat' || scopeKey === 'lon' || scopeKey === 'long') {
      const updatedRecords = foundRecords?.map(record => {
        if (record.data && typeof record.data === 'string') {
          let parsedData;
          try {
            // Always attempt to decrypt the data
            const decryptedData = CryptoSecretKey.fromString(encryptionMasterKey).decryptWithScopes(record.data);
            parsedData = JSON.parse(decryptedData);
          } catch (error) {
            // If decryption fails, assume the data is already decrypted
            parsedData = JSON.parse(record.data);
          }
          
          const scopedData = {};
          if (scopeKey === 'lat' && 'latitude' in parsedData) {
            scopedData['Latitude'] = parsedData.latitude;
          } else if ((scopeKey === 'lon' || scopeKey === 'long') && 'longitude' in parsedData) {
            scopedData['Longitude'] = parsedData.longitude;
          } else if ('geolocation_data' in parsedData && Array.isArray(parsedData.geolocation_data)) {
            scopedData[scopeKey === 'lat' ? 'Latitude' : 'Longitude'] = parsedData.geolocation_data.map(item => 
              item[scopeKey === 'lat' ? 'latitude' : 'longitude']
            );
          }
          return {
            ...record,
            scopedData: JSON.stringify(scopedData),
            isScoped: true
          };
        }
        return record;
      });
      setFoundRecords(updatedRecords ?? []);
      setShowScopeModal(false);
      setScopeDecryptError('');
    } else {
      setScopeDecryptError('Invalid scope encryption key!');
    }
  };

  return (
    <>
      <section>
        <div className={cx(bannerWrapper, 'flex items-center')}>
          <Container>
            <div className="banner-txt">
              <h1 className={cx(header, 'font-header')}>Decentralized Data viewer</h1>
            </div>
          </Container>
        </div>
      </section>
      <section>
        <div className={cx(radial, radialBlog)} />
        <Container>
          <div className={cx(table, 'grid gap-x-10 gap-y-6 py-20')}>
            <div className={tokenBox}>
              <h3 className={h3Header}>Load data</h3>
              {connectionError && (
                <p className="text-2xl text-red-300 mb-4">{connectionError}</p>
              )}
              <p className="text-2xl text-red-300">{loadError}</p>
              <form onSubmit={loadData} className="pb-8">
                <AccountField
                  required
                  name="appPubKey"
                  label="App Public Key"
                  value={appPubKey}
                  onChange={onAppPubKeyChange}
                  readonly={!!user?.principal}
                />
                <AccountField
                  required
                  name="userPubKey"
                  label="Account Public Key"
                  value={userPubKey}
                  onChange={onUserPubKeyChange}
                />
                <button
                  disabled={submitting || !accountFormValid}
                  className={cx(btn, 'relative -left-2 mt-4 flex justify-center items-center')}
                  type="submit"
                >
                  Load {submitting && <Loader />}
                </button>
              </form>
            </div>
            {foundRecords && foundRecords.length > 0 && (
              <div className={cx(recordChooser, 'my-4 pl-2')}>
                <div className="flex justify-between items-center mb-4">
                  <h3 className={h3Header}>User&apos;s Timeline</h3>
                  <div className="flex space-x-2">
                    <button
                      className={cx(btn, btnSmall)}
                      onClick={toggleAllEncryption}
                    >
                      {isEncryptMode ? 'Decrypt All' : 'Encrypt All'}
                    </button>
                    <button
                      className={cx(btn, btnSmall)}
                      onClick={() => setShowScopeModal(true)}
                    >
                      Decrypt Scope
                    </button>
                  </div>
                </div>
                <div className="container max-width-lg cd-timeline__container">
                  {foundRecords.map(({ data, timestamp, id, metadata }, index) => (
                    <div className="cd-timeline__block" key={id}>
                      <Zoom className="cd-timeline__img cd-timeline__img--picture" triggerOnce>
                        <i className="icon-check-1 event-icon" />
                      </Zoom>

                      <Bounce
                        className="cd-timeline__content text-component"
                        triggerOnce
                        direction={index % 2 === 0 ? 'left' : 'right'}
                      >
                        <>
                          <p className={cx(contentItem, 'color-contrast-medium data-content')}>
                            {isJSON(data) ? (
                              <JSONPretty
                                id={`json-pretty-${id}`}
                                data={
                                  foundRecords[index].isScoped
                                    ? JSON.parse(foundRecords[index].scopedData)
                                    : encryptedItems[id]
                                    ? generateMockEncryptedString()
                                    : JSON.parse(data)
                                }
                                className={jsonPretty}
                              />
                            ) : (
                              encryptedItems[id] ? generateMockEncryptedString() : data
                            )}
                          </p>
                          <div className="flex justify-end items-center">
                            <span className="cd-timeline__date">{new Date(timestamp).toLocaleString()}</span>
                            <button
                              className={cx(btn, btnSmall)}
                              type="button"
                              onClick={() => handleEncryptDecrypt(id)}
                            >
                              {encryptedItems[id] ? 'Decrypt' : 'Encrypt'}
                            </button>
                          </div>
                        </>
                      </Bounce>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {false && (
              <div className={tokenBox}>
                <h3 className={h3Header}>Decrypt data</h3>
                <p className="text-2xl text-red-300">{decryptError}</p>
                <form onSubmit={decrypt}>
                  <AccountField
                    required
                    readonly
                    monospaced
                    height={200}
                    name="data"
                    label="Raw data from Ddc"
                    value={rawData}
                    fieldType="textarea"
                  />
                  <AccountField
                    required
                    monospaced
                    name="paths"
                    label="JSON paths to encrypt"
                    onChange={setPaths}
                    value={paths}
                  />
                  <AccountField
                    required
                    readonly
                    monospaced
                    height={200}
                    name="decoded"
                    label="Decoded data"
                    value={decryptedData}
                    fieldType="textarea"
                  />
                  <AccountField name="encKey" label="Encryption master key" value="secret" />
                  <button disabled={submitting} className={cx(btn, 'relative -left-2 mt-4')} type="submit">
                    Decrypt
                  </button>
                </form>
              </div>
            )}
          </div>
        </Container>
      </section>
      <EncryptionKeyModal isOpen={showKeyModal} onClose={() => setShowKeyModal(false)} onSubmit={handleKeySubmit} />
      <ScopeDecryptionModal isOpen={showScopeModal} onClose={() => setShowScopeModal(false)} onSubmit={handleScopeDecrypt} error={scopeDecryptError} />
    </>
  );
}


function generateUniqueId(index: number): string {
  return `record-${index}-${Date.now()}`;
}

function generateMockEncryptedString(length: number = 64): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}