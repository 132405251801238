import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import './app/styles/index.pcss';
import { Main } from './app/main';

const rootNode = document.getElementById('root');
if (rootNode) {
  ReactDOM.render(
    <StrictMode>
      <Main />
    </StrictMode>,
    rootNode
  );
}
