import { useContext } from 'react';
import { AppContext } from './app-context';

export function useIsAuthenticated(): boolean {
  const { user } = useContext(AppContext);
  return Boolean(user);
}

export function useIsSubscribed(): boolean {
  const { user } = useContext(AppContext);
  return user != null && user?.subscription !== null;
}
